import createKindeClient, { AuthOptions, KindeClient } from "@kinde-oss/kinde-auth-pkce-js";
import { metricsBus } from "@/common/buses";
import { Location } from "react-router-dom";

interface IParams {
  login_hint: string;
  connection_id: string;
  redirectTo?: string;
}

export interface IKindeLocationState {
  redirectTo?: string;
}

class KindeService {
  private readonly client: Promise<KindeClient>;
  public constructor() {
    this.client = createKindeClient({
      client_id: import.meta.env.REACT_APP_KINDE_CLIENT_ID,
      domain: import.meta.env.REACT_APP_KINDE_DOMAIN,
      logout_uri: window.location.origin,
      redirect_uri: window.location.origin,
      on_redirect_callback: (user, { redirectTo, action }: { redirectTo?: string; action?: string } = {}) => {
        switch (action) {
        case "login":
          metricsBus.emit("loginCompleted");
          break;
        case "register":
          metricsBus.emit("signUpSuccess");
          break;
        default:
          console.warn("Undefined auth action");
        }

        if (redirectTo) {
          window.location.href = redirectTo;
        }
      },
    });
  }

  public async isAuthenticated() {
    const client = await this.client;
    return await client.isAuthenticated();
  }

  public async login({ login_hint, connection_id, redirectTo }: IParams) {
    const client = await this.client;

    const options: AuthOptions = {
      authUrlParams: { login_hint, connection_id },
    };

    if (redirectTo) {
      options.app_state = {
        redirectTo: redirectTo,
        action: "login",
      };
    }

    return await client.login(options);
  }

  public async register({ login_hint, connection_id, redirectTo }: IParams) {
    const client = await this.client;

    const options: AuthOptions = {
      authUrlParams: { login_hint, connection_id },
    };

    if (redirectTo) {
      options.app_state = {
        redirectTo: redirectTo,
        action: "register",
      };
    }

    return await client.register(options);
  }

  public async logout() {
    const client = await this.client;
    return await client.logout();
  }

  public async getIdToken() {
    const client = await this.client;
    return await client.getIdToken();
  }
}

export default new KindeService();
