import { create } from "zustand";

export enum tabValues {
  FOR_YOU = "forYou",
  TRENDING = "trending",
}

interface IHomeState {
  currentTab?: string;
  setCurrentTab: (currentTab: string) => void;
}

export const useHomeStore = create<IHomeState>(
  (set) => ({
    // actions
    setCurrentTab: (currentTab: string) => set(() => ({ currentTab })),
  }),
);
