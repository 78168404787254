import { useEffect } from "react";
import { useDeviceType } from "@/common/hooks/useDeviceType";
import { deviceTypeEnum } from "@/common/utils/device";
import { useAppStore } from "@/common/store/appStore";

export const addDeviceClass = (element: Element, device: deviceTypeEnum): void => {
  for (const key in deviceTypeEnum) {
    const deviceType = deviceTypeEnum[key as keyof typeof deviceTypeEnum];
    element.classList.toggle(deviceType, device === deviceType);
  }
};

export const useAppStyles = () => {
  const isProfileMenuOpen = useAppStore((state) => state.isProfileMenuOpen);
  const isSearchBarOpen = useAppStore((state) => state.isSearchBarOpen);
  const isBurgerMenuOpen = useAppStore((state) => state.isBurgerMenuOpen);
  const isFullScreen = useAppStore((state) => state.isFullScreen);
  const isForceFS = useAppStore((state) => state.isForceFS);

  const deviceType = useDeviceType();

  useEffect(() => {
    const body = document.body;
    if (isProfileMenuOpen || isBurgerMenuOpen || isSearchBarOpen) {
      // eslint-disable-next-line no-magic-numbers
      if (window.innerWidth > 1170 && isProfileMenuOpen) {
        return;
      }
      body.style.overflow = "hidden";
    } else if (isFullScreen) {
      body.style.overflow = "inherit";
    } else {
      body.style.overflow = "auto";
      body.style.overflowY = "scroll";
    }
  }, [isProfileMenuOpen, isBurgerMenuOpen, isSearchBarOpen, isFullScreen]);

  useEffect(() => {
    const element = document.querySelector("#root");

    if (!element) {
      return;
    }

    element.classList.toggle("fullScreen", isFullScreen);
    element.classList.toggle("expFullScreen", isForceFS);
    addDeviceClass(element, deviceType);
  }, [isFullScreen, isForceFS, deviceType]);
};
