import { useDeviceType } from "@/common/hooks/useDeviceType";
import { deviceTypeEnum } from "@/common/utils/device";
import { useLocation } from "react-router-dom";
import { routes } from "@/common/utils/routes";
import { UploadHeader } from "components/header/UploadHeader";
import { MainHeader } from "components/header/MainHeader";

import "./ApplicationHeader.scss";
import { useLessNavigation } from "@/common/hooks/useLessNavigation";

export const ApplicationHeader = () => {
  const location = useLocation();
  const deviceType = useDeviceType();
  const isDesktop = deviceType === deviceTypeEnum.DESKTOP;
  const { canShow } = useLessNavigation();

  if (!canShow) {
    return null;
  }

  return (
    <div className="topNav">
      {location.pathname.includes(routes.create()) && !isDesktop ? (
        <UploadHeader />
      ) : (
        <MainHeader />
      )}
    </div>
  );
};
