import RgLogo from "components/icons/RGLogo.svg?react";
import NounLiveIcon from "components/icons/nounlive.svg?react";
import { useCallback } from "react";
import classes from "./AdSearchLink.module.scss";
import { useExperiment } from "@/common/hooks/useExperiment";
import { EXPERIMENT_NO_ADS } from "@/common/services/experiment/constants";

export const AdSearchLink = () => {
  const { isLoading, variant: noAds } = useExperiment({ key: EXPERIMENT_NO_ADS, fallback: false });

  const handleClick = useCallback(() => {
    // eslint-disable-next-line
    window.location.href = "https://ptosrd.com/?siteId=wl3&cobrandId=257522&superCategoryName=&categoryName=&pageName=home&performerName=&prm[psid]=RedGIFs&prm[pstool]=205_1&prm[psprogram]=cbrnd&prm[campaign_id]=&subAffId={SUBAFFID}&prm[utm_source]=search&filters=";
  }, []);

  if (noAds || isLoading) {
    return null;
  }

  return (
    <div className={classes.advertSearchLink} onClick={handleClick}>
      <div className={classes.advertSearchIcon}>
        <NounLiveIcon />
      </div>
      <span>Discover more on <RgLogo width="12" height="9"/> Cams</span>
    </div>
  );
};
