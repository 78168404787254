import { RGLogo } from "components/UI/RGLogo";
import AdTab from "components/ads/AdTab";
import { tabs } from "components/ads/config/tabs";
import RgLogo from "components/icons/RGLogo.svg?react";
import { SearchInput } from "components/UI/SearchInput";
import { BurgerMenu } from "components/UI/BurgerMenu";
import { BurgerButton } from "components/buttons/BurgerButton/BurgerButton";
import { Fragment, Suspense, useCallback } from "react";
import { useDeviceType } from "@/common/hooks/useDeviceType";
import { deviceTypeEnum } from "@/common/utils/device";
import { useAppStore } from "@/common/store/appStore";

import "./MainHeader.scss";

export const MainHeader = () => {
  const isSearchBarOpen = useAppStore((state) => state.isSearchBarOpen);
  const isBurgerMenuOpen = useAppStore((state) => state.isBurgerMenuOpen);
  const deviceType = useDeviceType();
  const isDesktop = deviceType === deviceTypeEnum.DESKTOP;
  const toggleBurgerMenu = useAppStore((state) => state.toggleBurgerMenu);

  const handleBurgerMenuButton = useCallback(() => {
    toggleBurgerMenu();
  }, [toggleBurgerMenu]);

  return (
    <Fragment>
      {(isDesktop || !isSearchBarOpen) && <RGLogo />}
      <div className="topNav-wrap">
        <div>
          { isDesktop && <AdTab link={tabs.RGCams.link}>Explore <RgLogo width="18" height="12" /> Cams</AdTab> }
        </div>
        <SearchInput/>
        <div>
          { isDesktop && <AdTab link={tabs.LiveSex.link}>Watch Live Sex</AdTab> }
        </div>
        {(isDesktop || !isSearchBarOpen) && <BurgerButton onClick={handleBurgerMenuButton}/>}
      </div>
      {isBurgerMenuOpen && <Suspense><BurgerMenu /></Suspense>}
    </Fragment>
  );
};
