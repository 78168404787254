import { useEffect } from "react";
import { loadCookieNotice } from "@/common/utils/storage";
import { onOpenCookieNotice } from "@/common/buses";

const TIMEOUT = 1000;

export const useCookieNotice = () => {
  useEffect(() => {
    setTimeout(() => {
      try {
        if (window.location.pathname.indexOf("/ifr/") === 0) {
          return;
        }

        const value = loadCookieNotice();

        if (value === null) {
          onOpenCookieNotice();
        }
      } catch (e) {
        console.error("Local storage not available, not showing the cookie notice.");
      }
    }, TIMEOUT);
  }, []);
};
