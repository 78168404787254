import {
  IAnalyticsResponse,
  IExperimentList,
  IFollowersListResponse,
  IFollowingListResponse,
  IGallery,
  IGifInfo, IMeGifsResponse,
  IGifList,
  IMigrateResponse,
  IUser,
  IUserSearchResults,
  IUserSettings,
  ITagList,
  INicheList,
  IBoostedGifOptions,
  IAdSlotsResponse,
  IAdManageSlotsResponse,
  IGif,
  IGlobalNotifications,
  IAliasLink,
  IGetBestGifsParams,
  IStreamateCamera,
  IStreamateCameraRequest,
  ICreatorTagsResponse, VerifyResponse, IShortUserList,
  ITagFeedbackPayload,
} from "./types";

import AuthHttpService from "./AuthHttpService";
import { IGetModelsResult } from "./StripChatService";

interface IGifSearchParams {
  search_text?: string;
  order: string;
  count: number;
  type?: string;
  page?: number;
  following?: boolean;
  favourites?: boolean;
  verified?: boolean;
}

interface IGifReportParams {
  gifId: string;
  option: number;
  message: string;
  canContact: boolean;
  email: string;
}

interface ILikeGifParams {
  gifId: string;
  source: string | null;
}

export interface IUserGifsParams {
  order: string | null;
  isPublic: string | null;
  type: string;
  count: string | null;
  page: number | null;
  tags?: string[];
  isTrigger?: boolean;
}

export interface IOperation {
  op?: string;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export interface IPatchGifParams {
  hide_trending?: boolean;
  hide_home?: boolean;
  sexuality?: string;
  published?: boolean;
  description?: string;
  niches?: string[];
  tags?: string[] | null;
}

interface IOAuthCodeParams {
  client_id: string;
  scope: string;
  redirect_uri: string;
  state: string;
}

interface IOAuthCodeResult {
  url: string;
}

export default class RedGifsService extends AuthHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  async fetchGifById(gifId: string): Promise<IGifInfo> {
    return await this.get(`v2/gifs/${gifId}?views=yes&users=yes&niches=yes`);
  }

  async getExperiments(): Promise<IExperimentList> {
    const query = new URLSearchParams();
    query.append("source", window.location.href);

    return await this.get(`v2/experiments/all?${query.toString()}`);
  }

  async getForYouFeed(props: {
    page: number;
    count: number;
  }): Promise<IGifList> {
    const query = new URLSearchParams();

    if (props.page) {
      query.append("page", props.page.toString());
    }

    if (props.count) {
      query.append("count", props.count.toString());
    }

    return await this.get(`v2/feeds/for-you?${query.toString()}`);
  }

  async getHomeFeed(props: {
    page: number;
    count: number;
    viewing?: string;
    scene?: string | null;
    tags?: string | null;
  }): Promise<IGifList> {
    const query = new URLSearchParams();

    if (props.page) {
      query.append("page", props.page.toString());
    }

    if (props.count) {
      query.append("count", props.count.toString());
    }

    if (props.viewing) {
      query.append("viewing", props.viewing.toLocaleLowerCase());
    }

    if (props.scene) {
      query.append("scene", props.scene);
    }

    if (props.tags) {
      query.append("tags", props.tags);
    }

    return await this.get(`v2/feeds/home?${query.toString()}`);
  }

  async getLikedFeed(props: {
    page: number;
    count: number;
    type?: string;
  }): Promise<IGifList> {
    const query = new URLSearchParams();

    if (props.page) {
      query.append("page", props.page.toString());
    }

    if (props.count) {
      query.append("count", props.count.toString());
    }

    if (props.type) {
      query.append("type", props.type);
    }

    return await this.get(`v2/feeds/liked?${query.toString()}`);
  }

  async hideGif(props: {
    id: string;
    home: boolean;
    trending: boolean;
  }): Promise<void> {
    await this.patch(`v2/gifs/${props.id}`, {
      hide_home: props.home,
      hide_trending: props.trending,
    });
  }

  async likeGifById(params: ILikeGifParams) {
    return await this.put(`v2/gifs/${params.gifId}/like`, {
      source: params.source,
    });
  }

  async unlikeGifById(params: ILikeGifParams) {
    return await this.delete(`v2/gifs/${params.gifId}/like`, {
      source: params.source,
    });
  }

  async searchGifs(params: IGifSearchParams): Promise<IGifList> {
    const query = new URLSearchParams();
    params.order && query.append("order", params.order);
    params.count && query.append("count", params.count.toString());
    params.page && query.append("page", params.page.toString());
    params.type && query.append("type", params.type);
    params.search_text && query.append("search_text", params.search_text);
    params.following && query.append("following", "y");
    params.favourites && query.append("favourites", "y");
    params.verified && query.append("verified", "y");

    return await this.get(`v2/gifs/search?${query.toString()}`);
  }

  async searchUserGifs(params: {
    username: string;
    order: string;
    count: number;
    type?: string;
    page?: number;
    tags?: string;
    verified?: boolean;
  }): Promise<IGifList> {
    const query = new URLSearchParams();

    if (params.order) {
      query.append("order", params.order);
    }

    if (params.count) {
      query.append("count", params.count.toString());
    }

    if (params.type) {
      query.append("type", params.type);
    }

    if (params.page) {
      query.append("page", params.page.toString());
    }

    if (params.tags) {
      query.append("tags", params.tags);
    }

    const queryString = query.toString();
    return await this.get(`v2/users/${params.username}/search?${queryString}`);
  }

  async searchMyGifs(params: {
    order: string;
    count: number;
    type?: string;
    page?: number;
    tags?: string;
  }): Promise<IGifList> {
    const query = new URLSearchParams();

    if (params.order) {
      query.append("order", params.order);
    }

    if (params.count) {
      query.append("count", params.count.toString());
    }

    if (params.type) {
      query.append("type", params.type);
    }

    if (params.page) {
      query.append("page", params.page.toString());
    }

    if (params.tags) {
      query.append("tags", params.tags);
    }

    const queryString = query.toString();
    return await this.get(`v2/me/content?${queryString}`);
  }

  async searchSuggestions(payload: string){
    return await this.get(`v1/tags/suggest?query=${payload}`);
  }

  async fetchCreatorById(creatorId: string): Promise<IUser> {
    return await this.get(`v1/users/${creatorId}`);
  }

  async fetchCreatorTagsById(creatorId: string): Promise<ICreatorTagsResponse> {
    return await this.get(`v2/creators/${creatorId}/tags`);
  }

  async reportGif(params: IGifReportParams) {
    return await this.post(`v1/gifs/${params.gifId}/report-content`, {
      option: params.option,
      message: params.message,
      canContact: params.canContact,
      email: params.email
    });
  }

  async getMe(): Promise<IUser> {
    return await this.get("v1/me");
  }

  async updateProfileInfo(data: IOperation[]) {
    return await this.patch("v1/me", { operations: data });
  }

  async updateProfilePhoto(data: File) {
    return await this.put("v1/me/profile_image", data);
  }

  async deleteMyAccount() {
    return await this.delete("v1/me");
  }

  async followUser(username: string, context: string | null): Promise<void> {
    await this.put(`v1/me/follows/${username}`, {
      context,
    });
  }

  async unfollowUser(username: string): Promise<void> {
    await this.delete(`v1/me/follows/${username}`);
  }

  async fetchUserFollowers(props: {
    count?: number;
  }): Promise<IFollowersListResponse> {
    const params = new URLSearchParams();

    if (props.count) {
      params.append("count", props.count.toString());
    }

    return await this.get(`v1/me/followers/populated?${params.toString()}`);
  }

  async fetchUserFollows(props: {
    count?: number;
  }): Promise<IFollowingListResponse> {
    const params = new URLSearchParams();

    if (props.count) {
      params.append("count", props.count.toString());
    }

    return await this.get(`v1/me/follows/populated?${params.toString()}`);
  }

  async getFollowing(props: {
    count: number;
    page: number;
  }): Promise<IUserSearchResults> {
    const params = new URLSearchParams();
    params.append("count", props.count.toString());
    params.append("page", props.page.toString());

    return await this.get(`v2/me/following?${params.toString()}`);
  }

  async getFollowers(props: {
    count: number;
    page: number;
  }): Promise<IUserSearchResults> {
    const params = new URLSearchParams();
    params.append("count", props.count.toString());
    params.append("page", props.page.toString());

    return await this.get(`v2/me/followers?${params.toString()}`);
  }

  async getFollowingIds(): Promise<string[]> {
    return await this.get("v1/me/follows");
  }

  async deleteGif (gifId: string): Promise<void> {
    return await this.delete(`v1/me/gifs/${gifId}`);
  }

  async patchGif(id: string, params: IPatchGifParams) {
    const response = await this.patch(`v2/gifs/${id}`, params);
    return response;
  }

  fetchUserGifs ({ type, order, isPublic, count, page, tags }: IUserGifsParams): Promise<IMeGifsResponse> {
    const URLParams = new URLSearchParams({
      type,
    });

    if (order !== null) {
      URLParams.append("order", order);
    }

    if (isPublic !== null) {
      URLParams.append("public", isPublic === "1" ? "yes" : "no");
    }

    if (count !== null) {
      URLParams.append("count", count);
    }

    if (page !== null) {
      URLParams.append("page", page.toString());
    }

    if (tags && tags.length > 0) {
      URLParams.append("tags", tags.join(","));
    }

    return this.get(`v2/me/content?${URLParams.toString()}`);
  }

  async getLikedGifs(): Promise<string[]> {
    return await this.get("v2/likes");
  }

  async getGallery (galleryId: string): Promise<IGallery> {
    return await this.get(`v2/gallery/${galleryId}`);
  }

  async getRelatedGifs(params: {
    id: string;
    page: number;
    count: number;
  }): Promise<IGifList> {
    const url = `v2/recommend/tags/${params.id}?page=${params.page}&count=${params.count}`;
    return await this.get(url);
  }

  async getAIGifRecommendations(params: {
    id: string;
    count: number;
    cursor: string | undefined;
    filter?: string;
  }): Promise<IGifList> {
    const query = new URLSearchParams();
    query.append("count", params.count.toString());

    if (params.cursor) {
      query.append("recommendation_id", params.cursor);
    }

    if (params.filter) {
      query.append("filter", params.filter);
    }

    const url = `v2/recommend/ai/${params.id}?${query.toString()}`;

    return await this.get(url);
  }

  async getAITrendingRecommendations(props: {
    recommendationId: string | null;
    viewing?: string;
    scene?: string | null;
    tags?: string | null;
  }): Promise<IGifList> {
    const query = new URLSearchParams();

    if (props.recommendationId) {
      query.append("recommendation_id", props.recommendationId);
    }

    if (props.viewing) {
      query.append("viewing", props.viewing.toLocaleLowerCase());
    }

    if (props.scene) {
      query.append("scene", props.scene);
    }

    if (props.tags) {
      query.append("tags", props.tags);
    }

    return await this.get(`v2/recommend/ai/trending?${query.toString()}`);
  }

  async getUser(params: {
    userName: string;
  }): Promise<IUser> {
    return await this.get(`v1/users/${params.userName}`);
  }

  async sendGifMetrics(params: {
    event: string;
    gif: string;
    position?: number;
    source: string | null;
    boosted?: boolean;
    duration?: number;
    username?: string | null;
    fullscreen?: boolean;
  }): Promise<void> {
    console.debug("Sending gif metrics.", params);
    return await this.beacon("v2/metrics/gifs", params);
  }

  sendVideoLoaded(params: { duration: number; isHLS: boolean }): Promise<void> {
    return this.put("v2/metrics/video-loaded", params);
  }

  getChatModels(): Promise<IGetModelsResult> {
    return this.get("v2/chat-models");
  }

  async getOAuthCode(params: IOAuthCodeParams): Promise<IOAuthCodeResult> {
    return await this.post("/v2/oauth/code", params);
  }

  async getVerifyUserStatus(): Promise<VerifyResponse> {
    return await this.get("v2/me/verify");
  }

  async getBoostedGifs(options?: IBoostedGifOptions): Promise<{gifs: IGif[]; users: IUser[]}> {
    const params = new URLSearchParams(options as Record<string, string>);
    return await this.get(`v2/gifs/promoted?${params}`);
  }

  async getAnalytics(): Promise<IAnalyticsResponse> {
    return await this.get("v2/analytics");
  }

  updateUserSettings(params: IUserSettings) {
    return this.patch("v2/me/settings", params);
  }

  async getGifNiches(gifId: string): Promise<INicheList> {
    return await this.get(`v2/gifs/${gifId}/niches`);
  }

  async checkGifSound(gifId: string) {
    return await this.post(`v2/gifs/${gifId}/check-sound`);
  }

  async getAdSlots(): Promise<IAdSlotsResponse> {
    return await this.get("v2/adv/slots");
  }

  async getAdManageSlots(): Promise<IAdManageSlotsResponse> {
    return await this.get("v2/adv/manage/slots");
  }

  async updateAdManageSlots(names: string[]) {
    return await this.post("v2/adv/manage/slots", {
      slots: names
    });
  }

  /**
   * Request a single Streamate camera.
   */
  async getStreamateCamera(req: IStreamateCameraRequest): Promise<IStreamateCamera> {
    const query = new URLSearchParams();

    if (req.tags.length > 0) {
      query.append("tags", req.tags.join(","));
    }

    if (req.exclude.length > 0) {
      query.append("exclude", req.exclude.join(","));
    }

    const res = await this.get<Promise<{camera:IStreamateCamera}>>(`v2/cameron/streamate/camera?${query.toString()}`);

    return res.camera;
  }

  async getPinGifs(userName: string): Promise<IGifList> {
    return await this.get(`v2/pins/${userName}`);
  }

  async addPinGif(gif: string): Promise<void> {
    return await this.post("v2/pins", { gif });
  }

  async unPinGif(gif: string): Promise<void> {
    return await this.delete(`v2/pins/${gif}`);
  }

  async reorderPinGifs(gifs: string[]): Promise<void> {
    return await this.put("v2/pins", { ids: gifs });
  }

  async getGlobalNotifications(): Promise<IGlobalNotifications> {
    return await this.get("v2/notify/broadcast");
  }

  async deleteGlobalNotification(id: string): Promise<void> {
    return await this.delete(`v2/notify/broadcast/${id}`);
  }

  async addAliasLink(gifId: string): Promise<IAliasLink> {
    return await this.post("v2/alias", { gif: gifId });
  }

  async getAliasLinks(gifId: string): Promise<IAliasLink[]> {
    return await this.get(`v2/alias/gif/${gifId}`);
  }

  async getAliasLink(aliasId: string) {
    return await this.get(`v2/alias/${aliasId}`);
  }

  async removeAliasLink(aliasId: string) {
    return await this.delete(`v2/alias/${aliasId}`);
  }

  async getTaggedBestGifs({ tag }: IGetBestGifsParams): Promise<IGifList> {
    return await this.get(`v2/tags/${this.encodeComponent(tag)}/best-gifs`);
  }

  async getTaggedBestImages({ tag }: IGetBestGifsParams): Promise<IGifList> {
    return await this.get(`v2/tags/${this.encodeComponent(tag)}/best-images`);
  }

  async getTaggedCreators({ tag }: IGetBestGifsParams) {
    return await this.get<Promise<IShortUserList>>(`v2/tags/${this.encodeComponent(tag)}/creators`);
  }

  async getTaggedNiches({ tag }: IGetBestGifsParams) {
    return await this.get<Promise<INicheList>>(`v2/tags/${this.encodeComponent(tag)}/niches`);
  }

  async getGifTagsFeedback(gifId: string): Promise<{ tags: string[] }> {
    return await this.get(`v2/gifs/${gifId}/tag-feedback`);
  }

  async updateGifTagFeedback(gifId: string, payload: ITagFeedbackPayload) {
    return await this.put(`v2/gifs/${gifId}/tag-feedback`, payload);
  }
}
