/**
 * API query wrappers with cache etc.
 *
 * We often deploy queries ahead of the rest of the feature, to resolve conflicts,
 * so we allow unused functions for now.
 *
 */

/* eslint-disable no-magic-numbers */
/* eslint-disable import/no-unused-modules */

import { useMutation, useQuery, useQueryClient, useInfiniteQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

import { apiCollections, apiCreators, apiNiches, apiSearch, apiTags, apiMain, apiVisitor } from "@/common/services";
import { isAuthenticated } from "@/common/services/Auth";
import {
  IAnalyticsResponse,
  IBoostedGifOptions,
  ICollection,
  ICreatorsItemsList,
  IErrorData,
  IFollowingListResponse,
  IGallery,
  IGetBestGifsParams,
  IGetRelatedNicheParams,
  IGifInfo,
  IGifList,
  IGlobalNotifications,
  IHistoryList,
  INiche,
  INicheList,
  INichesItems,
  IShortUserList,
  ITag,
  ITagInfo,
  ITagList,
  ITagSuggestedOptions,
  ITagsList,
  IUser,
  IUserSearchResults,
} from "@/common/services/types";
import { IUserGifsParams, IPatchGifParams } from "@/common/services/RedGifsService";
import { IGetMatchedTagsParams } from "@/common/services/TagsService";
import { IUpdateParams } from "@/common/services/VisitorService";
import { UseQueryResult } from "react-query/types/react/types";
import urlPrefetcher from "@/common/utils/urlPrefetcher";
import { Passion } from "@/components/questionnaire/PassionStep/constants";

const TRENDING_TAGS_KEY = "trending-tags";
const TRENDING_CREATORS_KEY = "trending-creators";
const TRENDING_NICHES_KEY = "trending-niches";
const SINGLE_NICHE_KEY = "single-niche";

const DEFAULT_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: false,
};

export const useFollowingIdsQuery = () => {
  return useQuery(["following-ids"], async (): Promise<string[]> => {
    const auth = await isAuthenticated();

    if (auth) {
      return await apiMain.getFollowingIds();
    } else {
      return [];
    }
  }, DEFAULT_OPTIONS);
};

export const useFollowingQuery = (props: {
  cursor?: string;
}) => {
  return useQuery(["following"], async (): Promise<IFollowingListResponse|undefined> => {
    const auth = await isAuthenticated();

    if (auth) {
      return await apiMain.fetchUserFollows({
        count: 100,
      });
    } else {
      return undefined;
    }
  }, { ...DEFAULT_OPTIONS, cacheTime: 0 });
};

/**
 * Returns a list of gif ids that the user liked previously.
 */
export const useLikesQuery = () => {
  return useQuery(["likes"], async (): Promise<string[]> => {
    try {
      return await apiMain.getLikedGifs();
    } catch (e) {
      console.error("Error checking for user auth.", e);
      return [];
    }
  }, DEFAULT_OPTIONS);
};

export const useTrendingTags = () => {
  return useQuery([TRENDING_TAGS_KEY], async (): Promise<ITag[]> => {
    const res = await apiTags.getTrendingTags();
    return res;
  }, DEFAULT_OPTIONS);
};

export const useTrendingCreators = () => {
  return useQuery([TRENDING_CREATORS_KEY], async (): Promise<IUser[]> => {
    return (await apiCreators.getTrendingCreators()).items;
  }, DEFAULT_OPTIONS);
};

export const useTrendingNiches = (previews?: boolean) => {
  return useQuery([TRENDING_NICHES_KEY, previews], async (): Promise<INiche[]> => {
    const res = await apiNiches.getTrendingNiches(previews);
    return res.niches;
  }, DEFAULT_OPTIONS);
};

export const useSuggestedNiches = () => {
  return useQuery(["personal-suggested-niches"], async (): Promise<INiche[]> => {
    const auth = await isAuthenticated();

    if (auth) {
      return await apiNiches.getSuggestedNiches();
    } else {
      return [];
    }
  }, DEFAULT_OPTIONS);
};

export const useSingleNicheQuery = (id?: string) => {
  return useQuery<INiche | undefined, IErrorData>(
    [SINGLE_NICHE_KEY, id],
    async () => {
      if (id === undefined) {
        return undefined;
      }

      try {
        return await apiNiches.getNiche(id);
      } catch (e) {
        throw convertException(e);
      }
    }, DEFAULT_OPTIONS);
};

export const useUserInfo = (id: string|undefined): UseQueryResult<IUser, AxiosError<IErrorData>> => {
  return useQuery(["user-info", id], async (): Promise<IUser> => {
    if (id === undefined) {
      throw Error("user id not specified");
    }

    return await apiMain.fetchCreatorById(id);
  }, DEFAULT_OPTIONS);
};

export const useFollowingNichesQuery = (props: {
  previews: boolean;
  options?: UseQueryOptions<INicheList>;
}) => {
  return useQuery<INicheList>(
    ["following-niches", props],

    async (): Promise<INicheList> => {
      const auth = await isAuthenticated();

      if (auth) {
        console.debug("The user is authenticated, requesting following niches.");
        return await apiNiches.getFollowingNiches({
          previews: props.previews,
        });
      } else {
        console.debug("The user is not authenticated, not requesting following niches.");
        return { niches: [] };
      }
    },

    { ...DEFAULT_OPTIONS, ...(props.options || {}) },
  );
};

export const useNewVerifiedCreators = () => {
  return useQuery(
    ["new-verified-creators"],

    async (): Promise<IShortUserList> => {
      return await apiCreators.getNewVerifiedCreators();
    },

    DEFAULT_OPTIONS,
  );
};

interface INicheGifsQuery {
  id: string | undefined;
  page: number;
  type: string | undefined;
  order: string;
  count: number;
}

export const useNicheGifs = (params: INicheGifsQuery) => {
  return useQuery(
    ["niche-gifs", params],

    async ({ queryKey }): Promise<IGifList|undefined> => {
      if (params.id === undefined) {
        return undefined;
      }

      const gifList = await apiNiches.getNicheGifs({
        id: params.id,
        page: params.page,
        type: params.type,
        order: params.order,
        count: params.count,
      });

      urlPrefetcher.run(gifList.prefetch ?? []);

      return gifList;
    },

    DEFAULT_OPTIONS,
  );
};

export const useSearchCreatorsQuery = (params: {
    order: string;
    page: number;
    tags?: string;
    verified?: boolean;
}) => {
  return useQuery(
    ["search-creators", params],

    async ({ queryKey }): Promise<IUserSearchResults> => {
      return await apiCreators.searchCreators({
        order: params.order,
        page: params.page,
        tags: params.tags,
        verified: params.verified,
      });
    },

    DEFAULT_OPTIONS,
  );
};

export const useSearchGifsQuery = (params: {
  tag?: string;
  order: string;
  count: number;
  type?: string;
  page?: number;
  following?: boolean;
  favourites?: boolean;
  verified?: boolean;
}) => {
  return useQuery(
    ["search-gifs", params],

    async ({ queryKey }): Promise<IGifList> => {
      const gifList = await apiMain.searchGifs({
        search_text: params.tag,
        order: params.order,
        count: params.count,
        type: params.type,
        page: params.page,
        following: !!params.following,
        favourites: !!params.favourites,
        verified: !!params.verified,
      });

      urlPrefetcher.run(gifList.prefetch ?? []);

      return gifList;
    },

    DEFAULT_OPTIONS,
  );
};

interface ISearchUserGifsQuery {
  username: string;
  order: string;
  page: number;
  count?: number;
  type?: string;
  tags?: string;
}

export const useSearchUserGifs = (params: ISearchUserGifsQuery) => {
  return useQuery(
    ["search-user-gifs", params],

    async (): Promise<IGifList> => {
      return await apiMain.searchUserGifs({
        username: params.username,
        order: params.order,
        count: params.count ?? 10,
        type: params.type,
        page: params.page,
        tags: params.tags
      });
    },

    DEFAULT_OPTIONS,
  );
};

interface ISearchMyGifsQuery {
  order: string;
  page: number;
  type?: string;
  tags?: string;
}

export const useSearchMyGifs = (params: ISearchMyGifsQuery) => {
  return useQuery(
    ["search-my-gifs", params],

    async (): Promise<IGifList> => {
      return await apiMain.searchMyGifs({
        order: params.order,
        count: 50,
        type: params.type,
        page: params.page,
        tags: params.tags
      });
    },

    DEFAULT_OPTIONS,
  );
};

export const useGifQuery = (id: string, options: UseQueryOptions<IGifInfo, AxiosError<IErrorData>> = {}) => {
  return useQuery<IGifInfo, AxiosError<IErrorData>>(
    ["single-gif-info", id],

    async (): Promise<IGifInfo> => {
      return await apiMain.fetchGifById(id);
    },

    { ...DEFAULT_OPTIONS, ...options },
  );
};

export const useCollectionItemsQuery = (props: {
  user: string;
  collection: string;
  page: number;
  count: number;
  showPrivate?: boolean;
}) => {
  return useQuery(
    ["collection-items", props],

    async (): Promise<IGifList> => {
      if (props.showPrivate) {
        return await apiCollections.getMyCollectionItems({
          collectionId: props.collection,
          page: props.page,
          count: props.count
        });
      } else {
        return await apiCollections.getCollectionItems({
          userId: props.user,
          collectionId: props.collection,
          page: props.page,
          count: props.count
        });
      }
    },

    DEFAULT_OPTIONS,
  );
};

export const useAccountQuery = (props: {
  userName: string;
}) => {
  return useQuery(
    ["account-info", props],

    async (): Promise<IUser> => {
      return await apiMain.getUser({
        userName: props.userName,
      });
    },

    DEFAULT_OPTIONS,
  );
};

export const useVerifyUserProfileMutation = () => {
  return useMutation((verifyData: { [key: string]: string }) => {
    return apiVisitor.verifyUserProfile(verifyData);
  });
};

export const useCollectionQuery = (props: {
  userName: string;
  collectionId: string;
  showPrivate: boolean;
}) => {
  return useQuery(
    ["collection-info", props],

    async (): Promise<ICollection> => {
      if (props.showPrivate) {
        return await apiCollections.getMyCollection({
          collectionId: props.collectionId,
        });
      } else {
        return await apiCollections.getCollectionById({
          userName: props.userName,
          collectionId: props.collectionId,
        });
      }
    },

    DEFAULT_OPTIONS,
  );
};

export const useMeQuery = () => {
  return useQuery(
    ["get-me"],

    async (): Promise<IUser|undefined> => {
      const auth = await isAuthenticated();

      if (auth) {
        return await apiMain.getMe();
      }
    },

    DEFAULT_OPTIONS,
  );
};

export const useNichesQuery = (props: {
  order?: string;
  previews?: boolean;
  tag?: string;
}) => {
  return useQuery(
    ["all-niches", props],

    async (): Promise<INicheList> => {
      return await apiNiches.getNiches({
        order: props.order,
        tag: props.tag,
        previews: props.previews === undefined ? true : props.previews,
      });
    },

    DEFAULT_OPTIONS,
  );
};

export const useTopThisWeekQuery = () => {
  return useQuery(
    ["top-this-week"],

    async (): Promise<IGifList> => {
      return await apiSearch.getTopThisWeek();
    },

    DEFAULT_OPTIONS,
  );
};

export const useTrendingGifsQuery = () => {
  return useQuery(
    ["trending-gifs"],

    async (): Promise<IGifList> => {
      return await apiSearch.getTrendingGifs();
    },

    DEFAULT_OPTIONS,
  );
};

export const useTrendingImagesQuery = () => {
  return useQuery(
    ["trending-images"],

    async (): Promise<IGifList> => {
      return await apiSearch.getTrendingImages();
    },

    DEFAULT_OPTIONS,
  );
};

export const useGalleryQuery = (id: string|null|undefined) => {
  return useQuery(
    ["gallery", id],

    async (): Promise<IGallery|undefined> => {
      if (id) {
        console.debug(`Requesting contents of gallery ${id}`);
        return await apiMain.getGallery(id);
      } else {
        return undefined;
      }
    },

    DEFAULT_OPTIONS,
  );
};

export const useCreatorTagsQuery = (id: string) => {
  return useQuery(
    ["creators-tags", id],

    async () => {
      return await apiMain.fetchCreatorTagsById(id);
    },

    DEFAULT_OPTIONS,
  );
};

export const useTagsQuery = () => {
  return useQuery(
    ["tags"],

    async (): Promise<ITagList> => {
      return await apiTags.getTags();
    },

    DEFAULT_OPTIONS,
  );
};

export const useMeGifsQuery = (props: IUserGifsParams) => {
  const { isTrigger, ...filters } = props;

  return useQuery(
    ["meGifs", props],

    () => apiMain.fetchUserGifs(filters),

    DEFAULT_OPTIONS,
  );
};

export const useDeleteGifsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (ids: string[]) => {
      const requests = ids.map(id => apiMain.deleteGif(id));
      return await Promise.all(requests);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: ["meGifs"] });
      },
    }
  );
};

export const useChangeGifsPrivacyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ ids, value }: { ids: string[]; value: 0 | 1 }) => {
      const requests = ids.map(id => apiMain.patchGif(id, { published: !!value }));

      return await Promise.all(requests);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: ["meGifs"] });
      },
    }
  );
};

export const useChangeGifTagsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ ids, tags }: { ids: string[]; tags: string[] }) => {
      const requests = ids.map(id => apiMain.patchGif(id, { tags }));
      return await Promise.all(requests);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: ["meGifs"] });
      },
    }
  );
};

/**
 * Perform a number of different requests for the related gifs,
 * depending on where the user comes from.
 */
export const useSmartRelatedGifsQuery = (props: {
  gif: string | undefined;
  rel: string;
  page: number;
  verified: boolean;
  order: string | undefined;
  count: number;
}) => {
  return useQuery<IGifList | undefined, AxiosError>(
    ["related-gifs-smart", props.gif, props.rel, props.page],

    async (): Promise<IGifList | undefined> => {
      let gifList;

      const m1 = props.rel.match(/^niche:(.+)/);

      if (m1 !== null) {
        console.debug(`Smart feed source: niche ${m1[1]} page=${props.page}.`);

        const requestedNiches: string[] = [];
        const getNicheGifs = async (niche: string): Promise<IGifList | undefined> => {
          requestedNiches.push(niche);

          return await apiNiches.getNicheGifs({
            id: niche,
            page: props.page,
            count: 100,
            type: undefined,
            order: props.order || "trending",
          }).catch(e => {
            const error = convertException(e).error;
            if (!error) {
              throw e;
            }

            const { code, target } = error;
            if (code === "NicheMoved" && target) {
              if (!requestedNiches.includes(target)) {
                return getNicheGifs(target);
              }

              throw new Error("Niche loop detected");
            }

            throw e;
          });
        };

        gifList = await getNicheGifs(m1[1]);
      }

      const m2 = props.rel.match(/^tag:([^,]+),([agi])/);

      if (m2 !== null) {
        console.debug(`Smart feed source: tag ${m2[1]}, mode=${m2[2]} page=${props.page}.`);

        gifList = await apiMain.searchGifs({
          search_text: m2[1],
          page: props.page,
          count: 100,
          order: props.order || "trending",
          verified: props.verified,
          type: m2[2] === "a" ? undefined : m2[2],
        });
      }

      const m3 = props.rel.match(/^user:(.+)/);

      if (m3 !== null) {
        console.debug(`Smart feed source: user ${m3[1]} page=${props.page}.`);

        const auth = await isAuthenticated();
        const itsMe = auth ? (await apiMain.getMe()).username === m3[1] : false;
        const options = {
          order: props.order || "trending",
          count: 100,
          type: undefined,
          page: props.page
        };

        gifList = itsMe
          ? await apiMain.searchMyGifs(options)
          : await apiMain.searchUserGifs({ username: m3[1], ...options });
      }

      const m4 = props.rel.match(/^c:(.+)/);

      if (m4 !== null) {
        const splitedRel = props.rel.split(":");

        const auth = await isAuthenticated();
        let itsMe = false;

        if (auth) {
          const user = await apiMain.getMe();
          if (user.username === splitedRel[1]) {
            itsMe = true;
          }
        }

        console.debug(`Smart feed source: collection ${m4[1]} page=${props.page}.`);

        if (itsMe) {
          gifList = await apiCollections.getMyCollectionItems({
            collectionId: splitedRel[2],
            page: props.page,
            count: 100
          });
        } else {
          gifList = await apiCollections.getCollectionItems({
            userId: splitedRel[1],
            collectionId: splitedRel[2],
            page: props.page,
            count: 100
          });
        }
      }

      console.debug(`Smart feed source: default, rel=${props.rel} page=${props.page}`);

      if (!gifList && props.gif) {
        gifList = await apiMain.getRelatedGifs({ id: props.gif, page: props.page, count: props.count });
      }

      if (gifList) {
        urlPrefetcher.run(gifList.prefetch ?? []);
        return gifList;
      }
    },

    DEFAULT_OPTIONS,
  );
};

export const useForYouFeedQuery = (props: {
  page: number;
  count: number;
}) => {
  return useQuery(
    ["for-you-feed", props],

    async () => {
      const gifList = await apiMain.getForYouFeed({
        page: props.page,
        count: props.count,
      });

      urlPrefetcher.run(gifList.prefetch ?? []);

      return gifList;
    },

    DEFAULT_OPTIONS,
  );
};

export const useHomeFeedQuery = (props: {
  page: number;
  count: number;
  viewing?: Passion;
}) => {
  return useQuery<IGifList, AxiosError>(
    ["home-feed-default", props],

    async () => {
      const gifList = await apiMain.getHomeFeed({
        page: props.page,
        count: props.count,
        viewing: props.viewing
      });

      urlPrefetcher.run(gifList.prefetch ?? []);

      return gifList;
    },

    { ...DEFAULT_OPTIONS, cacheTime: 0 }
  );
};

export const useLikedFeedQuery = (props: {
  page: number;
  count: number;
  type?: string;
}) => {
  return useQuery(
    ["liked-feed", props],

    () => apiMain.getLikedFeed({
      page: props.page,
      count: props.count,
      type: props.type,
    }),

    DEFAULT_OPTIONS,
  );
};

export const useMatchedTagsQuery = (props: IGetMatchedTagsParams) => {
  return useQuery(
    ["matchedTags", props],

    () => {
      return apiTags.getMatchedTags(props);
    },

    DEFAULT_OPTIONS,
  );
};

export const useFollowingFeed = () => {
  const fetch = async ({
    pageParam = 1,
  }): Promise<IUserSearchResults> => {
    return await apiMain.getFollowing({
      count: 100,
      page: pageParam as number,
    });
  };

  return useInfiniteQuery("following-feed", fetch, {
    getNextPageParam: (lastPage: IUserSearchResults, pages) => {
      if (lastPage.page < lastPage.pages) {
        return lastPage.page + 1;
      } else {
        return null;
      }
    },
    ...DEFAULT_OPTIONS,
  });
};

export const useFollowersFeed = () => {
  const fetch = async ({
    pageParam = 1,
  }): Promise<IUserSearchResults> => {
    return await apiMain.getFollowers({
      count: 100,
      page: pageParam,
    });
  };

  return useInfiniteQuery("followers-feed", fetch, {
    getNextPageParam: (lastPage: IUserSearchResults, pages) => {
      if (lastPage.page < lastPage.pages) {
        return lastPage.page + 1;
      } else {
        return null;
      }
    },
    ...DEFAULT_OPTIONS,
  });
};

export const useDeleteAccountMutation = () => useMutation(() => apiMain.deleteMyAccount());

export const useUserCollections = (props: {
  userName: string;
  isRefetch: boolean;
}) => {
  const fetch = async ({
    pageParam = 1,
  }) => {
    return await apiCollections.getUserCollections({
      userId: props.userName,
      page: pageParam as number,
      count: 50,
    });
  };

  return useInfiniteQuery(["user-collection", props.isRefetch], fetch, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page < lastPage.pages) {
        return lastPage.page + 1;
      } else {
        return null;
      }
    },
    ...DEFAULT_OPTIONS,
    refetchOnWindowFocus: true,
    refetchOnMount: true
  });
};

export const useAddUserCollectionMutation = () => useMutation(
  (props: {
    published: boolean;
    description: string | null;
    folderName: string;
  }) => apiCollections.createCollection(props),
);

export const useUpdateVisitorInfoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (props: IUpdateParams[]) => apiVisitor.updateVisitorInfo(props),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("get-me");
      },
    }
  );
};

export const useAddLikeMutation = () => useMutation(
  (props: { gifId: string; source: string | null }) => apiMain.likeGifById(props),
);

export const useRemoveLikeMutation = () => useMutation(
  (props: { gifId: string; source: string | null }) => apiMain.unlikeGifById(props),
);

export const useBoostedGifs = (options?: IBoostedGifOptions) => {
  return useQuery(
    ["boosted-gifs", options],
    async () => {
      if (options?.gif === null) {
        return undefined;
      } else {
        return await apiMain.getBoostedGifs(options);
      }

    },
    DEFAULT_OPTIONS,
  );
};

export const useAddToNiche = () => useMutation(
  (props: { gifId: string; nicheIds: string[] }) => apiNiches.setGifNiches(props),
);

export const useAnalyticsQuery = () => {
  return useQuery<IAnalyticsResponse, IErrorData>(
    ["analytics"],
    async () => {
      try {
        return await apiMain.getAnalytics();
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGifNichesQuery = (id: string, options: UseQueryOptions<INicheList> = {}) => {
  return useQuery<INicheList>(
    ["gif-niche", id],
    async () => {
      try {
        return await apiMain.getGifNiches(id);
      } catch (e) {
        throw convertException(e);
      }
    },
    { ...DEFAULT_OPTIONS, ...options },
  );
};

export const useGifSuggestedNichesQuery = (id: string, options: UseQueryOptions<INicheList> = {}) => {
  return useQuery<INicheList>(
    ["suggested-niche", id],
    async () => {
      try {
        return await apiNiches.getGifSuggestedNiche(id);
      } catch (e) {
        throw convertException(e);
      }
    },
    { ...DEFAULT_OPTIONS, ...options },
  );
};

export const useAddGifSuggestedNichesQuery = (tags: string[]) => {
  return useQuery<INicheList>(
    ["upload-suggested-niche", tags],
    async () => {
      try {
        return await apiNiches.getAddGifSuggestedNiches(tags);
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useEditNicheMutation = (nicheId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (props: {
      id: string;
      description: string;
      rules: string;
      image: string | null;
    }) => apiNiches.editNiche(props),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: [SINGLE_NICHE_KEY, nicheId] });
      },
    }
  );
};

export const useFollowUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (props: {
      username: string;
      context: string | null;
    }) => apiMain.followUser(props.username, props.context),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["following-ids"] });
      },
    }
  );
};

export const useUpdateGifMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, gifParams }: { id: string; gifParams: IPatchGifParams }) => apiMain.patchGif(id, gifParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["meGifs"] });
      }
    }
  );
};

export const useGetPins = (username: string) => {
  return useQuery(
    ["pins", `pins-${username}`],

    async (): Promise<IGifList> => {
      return await apiMain.getPinGifs(username);
    },

    { ...DEFAULT_OPTIONS },
  );
};

export const useAddPinGifMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (gif: string) => apiMain.addPinGif(gif),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["pins"] });
      }
    }
  );
};

export const useUnPinGifMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (gif: string) => apiMain.unPinGif(gif),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["pins"] });
      }
    }
  );
};

export const useGetTagInfoQuery = (id: string | undefined) => {
  return useQuery(
    ["tag-info", id],

    async (): Promise<ITagInfo | undefined> => {
      if (id) {
        return await apiTags.getTagInfo(id);
      } else {
        return undefined;
      }
    },

    DEFAULT_OPTIONS,
  );
};

export const useReorderPinGifsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (gifs: string[]) => apiMain.reorderPinGifs(gifs),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["pins"] });
      }
    }
  );
};

export const useGlobalNotificationsQuery = (username: string) => {
  return useQuery<IGlobalNotifications | undefined>(
    ["global-notifications", username],
    async () => {
      try {
        return await apiMain.getGlobalNotifications();
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useDeleteGlobalNotificationMutation = (username: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => apiMain.deleteGlobalNotification(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["global-notifications", username] });
      }
    }
  );
};

export const useAliasLinksQuery = (gifId: string) => {
  return useQuery(
    ["alias-links", gifId],
    async () => {
      try {
        return await apiMain.getAliasLinks(gifId);
      } catch (e) {
        throw convertException(e);
      }
    },
    { ...DEFAULT_OPTIONS, cacheTime: 0 },
  );
};

export const useAddAliasLinkMutation = (gifId: string) => {
  return useMutation(
    () => apiMain.addAliasLink(gifId),
  );
};

export const useGetTagSuggestedQuery = (options: ITagSuggestedOptions) => {
  return useQuery(
    ["tags-suggested", options.query],
    async () => {
      try {
        return await apiTags.getTagSuggested(options);
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetBestImagesQuery = ({ tag }: IGetBestGifsParams) => {
  return useQuery(
    ["best-images", tag],
    async (): Promise<IGifList> => {
      try {
        return await apiMain.getTaggedBestImages({ tag });
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetTaggedNichesQuery = ({ tag }: IGetBestGifsParams) => {
  return useQuery(
    ["tagged-niches", tag],
    async () => {
      try {
        return await apiMain.getTaggedNiches({ tag });
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetNichesTagsQuery = ({ niche }: IGetRelatedNicheParams) => {
  return useQuery(
    ["niche-tags", niche],
    async () => {
      try {
        return await apiNiches.getNichesTags({ niche });
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetNichesCreatorsQuery = ({ niche }: IGetRelatedNicheParams) => {
  return useQuery(
    ["niche-creators", niche],
    async () => {
      try {
        return await apiNiches.getNichesCreators({ niche });
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetRelatedNichesQuery = ({ niche }: IGetRelatedNicheParams) => {
  return useQuery(
    ["related-niches", niche],
    async () => {
      try {
        return await apiNiches.getRelatedNiches({ niche });
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetTaggedCreatorsQuery = ({ tag }: IGetBestGifsParams) => {
  return useQuery(
    ["tagged-creators", tag],
    async () => {
      try {
        return await apiMain.getTaggedCreators({ tag });
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetTagsSearchesQuery = ({ tag }: IGetBestGifsParams) => {
  return useQuery(
    ["tags-suggest", tag],
    async () => {
      try {
        return await apiSearch.getTagsSearches(tag);
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetMyNiches = (params: { previews: boolean }) => {
  return useQuery(
    ["my-niches"],
    async () => {
      try {
        return await apiNiches.getMyNiches(params);
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetTrendingSearches = (enabled: boolean) => {
  return useQuery(
    ["trending-searches"],
    async () => {
      try {
        return await apiSearch.getTrendingSearches();
      } catch (e) {
        throw convertException(e);
      }
    },
    { ...DEFAULT_OPTIONS, enabled },
  );
};

const useGetSearches = <T>(
  keyPrefix: string,
  searchText: string,
  fetchFunction: (searchText: string) => Promise<T>,
  enabled: boolean
) => {
  const queryClient = useQueryClient();

  return useQuery(
    [`${keyPrefix}/${searchText}`],
    async () => {
      const cache = queryClient.getQueryData<T>(`${keyPrefix}/${searchText}`);

      if (cache) {
        return cache;
      }

      try {
        return await fetchFunction(searchText);
      } catch (e) {
        throw convertException(e);
      }
    },
    { ...DEFAULT_OPTIONS, enabled },
  );
};

export const useGetHistorySearches = (searchText: string, enabled: boolean) =>
  useGetSearches<IHistoryList[]>("history-searches", searchText, apiSearch.getHistorySearches.bind(apiSearch), enabled);

export const useGetCreatorsSearches = (searchText: string) =>
  useGetSearches<ICreatorsItemsList>("creators-searches", searchText, apiSearch.getCreatorsSearches.bind(apiSearch), !!searchText);

export const useGetNichesSearches = (searchText: string) =>
  useGetSearches<INichesItems[]>("niches-searches", searchText, apiSearch.getNichesSearches.bind(apiSearch), !!searchText);

export const useGetTagsSearches = (searchText: string) =>
  useGetSearches<ITagsList[]>("tags-searches", searchText, apiSearch.getTagsSearches.bind(apiSearch), !!searchText);

export const useGetAdSlots = () => {
  return useQuery(
    ["ad-slots"],
    async () => {
      try {
        return await apiMain.getAdSlots();
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const useGetExperiments = () => {
  return useQuery(
    ["experiments"],
    async () => {
      try {
        return await apiMain.getExperiments();
      } catch (e) {
        throw convertException(e);
      }
    },
    DEFAULT_OPTIONS,
  );
};

export const convertException = (e: unknown): IErrorData => {
  if (e instanceof AxiosError) {
    if (e.response?.data) {
      return e.response.data as IErrorData;
    }

    console.error("OOPS, unknown axios error.", e.response);
  } else {
    console.error("OOPS, unknown exception.", e);
  }

  return {
    error: {
      code: "UnknownError",
      message: "We could not process this request, please try again later.",
      status: 500,
    }
  } as IErrorData;
};
