import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig, Method, AxiosRequestHeaders } from "axios";
import { createApiUrl } from "@/common/utils/urls";

const defaultTimeout = 10000;

export const STATUS_NOT_FOUND = 404;
export const STATUS_GONE = 410;
export const STATUS_OK = 200;

export interface ErrorResponseData {
  error: { code: string; message: string; status: number };
}

export abstract class BaseHttpService {
  private readonly axiosClient: AxiosInstance;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly promiseRequests: {[key: string]: Promise<any> } = {};

  protected constructor (readonly baseURL?: string) {
    this.axiosClient = axios.create({
      baseURL: baseURL ?? import.meta.env.REACT_APP_API_BASE_URL ?? "/",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public async get<Response extends Object>(url: string, headers?: AxiosRequestHeaders): Promise<Response> {
    const key = JSON.stringify({
      method: "get",
      url,
      headers
    });

    if (key in this.promiseRequests) {
      return this.promiseRequests[key];
    }

    this.promiseRequests[key] = this.request<Response>("get", url, undefined, headers);

    try {
      return await this.promiseRequests[key];
    } finally {
      delete this.promiseRequests[key];
    }
  }

  /**
   * Send a beacon, when available, otherwise a regular POST.
   */
  protected async beacon<Response,Params>(path: string, data?: Params): Promise<Response|undefined> {
    if (navigator.sendBeacon && window.Blob) {
      const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
      const url = createApiUrl(path);

      try {
        navigator.sendBeacon(url, blob);
      } catch (e) {
        console.error(`Error sending beacon: ${e}`);
      }
    } else {
      return await this.request<Response,Params>("post", path, data);
    }
  }

  protected async post<Response,Params>(url: string, data?: Params, headers?: AxiosRequestHeaders) {
    return await this.request<Response,Params>("post", url, data, headers);
  }

  protected async patch<Response,Params>(url: string, data?: Params, headers?: AxiosRequestHeaders) {
    return await this.request<Response,Params>("patch", url, data, headers);
  }

  protected async put<Response,Params>(url: string, data?: Params, headers?: AxiosRequestHeaders) {
    return await this.request<Response,Params>("put", url, data, headers);
  }

  protected async delete<Response,Params>(url: string, data?: Params, headers?: AxiosRequestHeaders) {
    return await this.request<Response,Params>("delete", url, data, headers);
  }

  protected request<Response,Params = undefined>(method: Method, url: string, data?: Params, headers?: AxiosRequestHeaders): Promise<Response> {
    // eslint-disable-next-line import/no-named-as-default-member
    const source = axios.CancelToken.source();

    const config: AxiosRequestConfig<Params> = {
      method,
      url,
      data,
      headers,
      cancelToken: source.token
    };

    setTimeout(() => {
      source.cancel("Internal Server Error");
    }, defaultTimeout);

    return this.axiosClient
      .request<Response>(config)
      .then(response => response.data);
  }

  /**
   * Our backend (Slim Framework) currently has a bug that decodes components
   * twice, so we need to encode them twice also.
   *
   * https://trello.com/c/45e8pc2a
   */
  protected encodeComponent(value: string): string {
    return encodeURIComponent(encodeURIComponent(value));
  }
}
