import { useEffect, useState } from "react";
import experimentService from "@/common/services/experiment/experimentService";
import { EXPERIMENT_COVERAGE_MAP } from "@/common/services/experiment/constants";

interface IExperimentProps {
  key: string;
  fallback: string | boolean;
}

export const useExperiment = ({ key, fallback }: IExperimentProps) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [variant, setVariant] = useState<string | boolean>(fallback);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const coverage = EXPERIMENT_COVERAGE_MAP[key] || 1;
      const variant = await experimentService.getVariant(key, fallback, coverage);

      setVariant(variant);
      setLoading(false);
    })();
  }, []);

  return {
    isLoading,
    variant
  };
};
