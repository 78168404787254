/**
 * Adds bus handlers to maintain the list of recently displayed cameras.
 */

import { useEffect } from "react";
import { mainBus } from "@/common/buses";
import { addCamera, resetCameras } from "@/common/utils/streamate";

export const useStreamateCameras = () => {
  useEffect(() => {
    mainBus.on("add_streamate_camera", (id: number) => {
      addCamera(id);
    });

    mainBus.on("clear_streamate_cameras", () => {
      resetCameras();
    });
  }, []);
};
