import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { ClosePopupEvent, OpenPopupEvent, popupBus } from "@/common/buses";
import { popups } from "./popups";
import { Loader } from "components/UI/Loader";
import { Backdrop } from "components/popups/Backdrop";
import { useAppStore } from "@/common/store/appStore";

export const PopupDispatcher = () => {
  const isFullScreen = useAppStore((state) => state.isFullScreen);
  const [openPopups, setOpenPopups] = useState<OpenPopupEvent[]>([]);

  const children = useMemo(() => {
    return openPopups.map(({ name, ...props }) => {
      const Component = popups[name];
      // @ts-ignore
      return (<Component key={name} { ...props } />);
    });
  }, [openPopups]);

  const addPopupHandler = useCallback((event: OpenPopupEvent) => {
    setOpenPopups(prevState => {
      const index = prevState.findIndex(({ name }) => name === event.name);
      const newState = [...prevState];

      if (index !== -1) {
        newState[index] = event;
      } else {
        newState.push(event);
      }

      return newState;
    });
  }, []);

  const closePopupHandler = useCallback((event: ClosePopupEvent) => {
    setOpenPopups(prevState => {
      const index = prevState.findIndex(({ name }) => name === event.name);

      if (index === -1) {
        return prevState;
      }

      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  }, []);

  useEffect(() => {
    setOpenPopups([]);
  }, [isFullScreen]);

  useEffect(() => {
    popupBus.on("open", addPopupHandler);
    popupBus.on("close", closePopupHandler);
  }, [addPopupHandler, closePopupHandler]);

  return (
    <Suspense fallback={<Backdrop><Loader /></Backdrop>}>
      { children }
    </Suspense>
  );
};
