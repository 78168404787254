import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalSearch } from "components/UI/GlobalSearch";
import { decodeTags } from "@/common/utils/strings";
import { clsx } from "clsx";
import { useDeviceType } from "@/common/hooks/useDeviceType";
import { deviceTypeEnum } from "@/common/utils/device";
import ArrowIcon from "components/icons/left_arrow.svg?react";
import { PinkBaseButton } from "components/buttons/PinkBaseButton";
import { routes } from "@/common/utils/routes";
import { useQuery } from "@/common/hooks/useQuery";
import "./styles/SearchInput.scss";
import { useAppStore } from "@/common/store/appStore";
import { metricsBus } from "@/common/buses";

export const SearchInput = () => {
  const toggleSearchBar = useAppStore((state) => state.toggleSearchBar);
  const [currentSearchValue, setCurrentSearchValue] = useState("");
  const [currentTag, setCurrentTag] = useState<string[]>([]);
  const searchRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isSearchBarOpen = useAppStore((state) => state.isSearchBarOpen);
  const deviceType = useDeviceType();
  const isDesktop = deviceType === deviceTypeEnum.DESKTOP;
  const isShowCloseBtn = isDesktop || currentSearchValue;
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    const match = location.pathname.match(/^\/gifs\/([^/]+)/);
    if (match !== null) {
      const tag = match[1];
      const decodedTags = decodeTags(tag);

      setCurrentTag(decodedTags);
    } else {
      setCurrentTag(prevState => prevState.length > 0 ? [] : prevState);
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        if (isSearchBarOpen) {
          toggleSearchBar();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef, toggleSearchBar, isSearchBarOpen]);

  const handleOpenSearchBar = () => {
    if (!isSearchBarOpen) {
      metricsBus.emit("searchBarOpened");
      toggleSearchBar();
    }
  };

  const handleCloseAndReset = () => {
    setCurrentSearchValue("");
    setCurrentTag(prevState => prevState.length > 0 ? [] : prevState);

    if (isDesktop) {
      toggleSearchBar();
    }
  };

  const handleCloseMobile = () => {
    toggleSearchBar();
  };

  const searchInputHandler = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !currentTag.length && currentSearchValue.length) {
      navigate(routes.search(currentSearchValue));
      handleCloseMobile();
      searchInputRef.current?.blur();
    }
  };

  const removeTag = (title: string) => {
    const newInputList = currentTag.filter(tag => title !== tag);
    setCurrentTag(newInputList);

    if (!newInputList.length) {
      navigate(routes.explore());
    } else {
      navigate(routes.multipleTag(newInputList, query.get("order") ?? undefined, !!query.get("verified")));
    }
  };

  const renderTags = () => {
    if (!currentTag.length) {
      return null;
    }

    return (
      <div className="SearchInput-Tags">
        {currentTag.map(tag => (
          <PinkBaseButton key={tag} isSelected aria-label={`${tag} tag`}>
            {tag}
            <div onClick={() => removeTag(tag)} />
          </PinkBaseButton>
        ))}
      </div>
    );
  };

  const searchInputClsx = clsx({
    "SearchInput": true,
    "SearchInput_isOpen": isSearchBarOpen,
    "SearchInput_isWithTags": !!currentTag.length
  });

  return (
    <div className={searchInputClsx} ref={searchRef}>
      {!isDesktop && isSearchBarOpen && (
        <button className="SearchInput-CancelBtn" onClick={handleCloseMobile} aria-label="close search">
          <ArrowIcon />
        </button>
      )}
      {/* {currentTag && <PinkBaseButton onClick={handleOpenSearchBar} isSelected>{currentTag}</PinkBaseButton>} */}
      <div className="SearchInput-InputWrapper">
        <div className={clsx(["SearchInput-SearchIcon", { "SearchInput-SearchIcon_isOpen": isSearchBarOpen }])}/>
        {renderTags()}
        <input
          ref={searchInputRef}
          id="global-search-input"
          type="text"
          autoComplete="off"
          placeholder="Search by..."
          value={currentSearchValue}
          onChange={searchInputHandler}
          onSelect={handleOpenSearchBar}
          onKeyDown={handleKeyDown}
        />
        { isSearchBarOpen && isShowCloseBtn && <div className="SearchInput-CloseIcon" onClick={handleCloseAndReset} /> }
      </div>
      <GlobalSearch
        setSearchInput={setCurrentSearchValue}
        currentSearchValue={currentSearchValue}
        setCurrentTag={setCurrentTag}
      />
    </div>
  );
};
