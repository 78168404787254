/* eslint-disable no-magic-numbers */
import { metricsBus } from "@/common/buses";
import recombeeClient from "./recombeeClient";
import { useRecommendedFeedStore } from "@/common/store/recommendedFeedStore";

const isInteractionAllowed = () => {
  const isWatchPage = window.location.pathname.indexOf("/watch/") === 0;
  if (isWatchPage) {
    return true;
  }

  return useRecommendedFeedStore.getState().interactionsEnabled;
};

metricsBus.on("gifView", async ({ gifId }) => {
  if (await isInteractionAllowed()) {
    recombeeClient.sendDetailView(gifId);
  }
});

metricsBus.on("gifPlayback", async ({ gif, percent }) => {
  if (await isInteractionAllowed()) {
    recombeeClient.sendPortionView(gif, percent / 100);
  }
});

metricsBus.on("addedToCollection", async ({ gifId }) => {
  if (await isInteractionAllowed()) {
    recombeeClient.sendAddBookmark(gifId);
  }
});

metricsBus.on("gifLiked", async ({ gifId }) => {
  if (await isInteractionAllowed()) {
    recombeeClient.sendAddRating(gifId, 1);
  }
});
