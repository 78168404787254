import { AwsRum, AwsRumConfig } from "aws-rum-web";

const ENABLED_COUNTRIES = ["US", "UK", "DE"];

const ENABLED_ENVIRONMENTS = ["production"];

declare global {
  interface Window {
    geo_country: string;
  }
}

export const getPageTags = (pathname: string): string[] => {
  if (pathname.startsWith("/watch/")) {
    return ["watch"];
  }

  if (pathname.startsWith("/ifr/")) {
    return ["ifr"];
  }

  if (pathname.startsWith("/users/")) {
    return ["profile"];
  }

  if (pathname.startsWith("/gifs/")) {
    return ["tag"];
  }

  if (pathname.startsWith("/niches/")) {
    return ["niche"];
  }

  return [];
};

const initializeRum = () => {
  const country = window.geo_country ?? null;

  if (!ENABLED_COUNTRIES.includes(country)) {
    return;
  }

  if (!ENABLED_ENVIRONMENTS.includes(import.meta.env.MODE)) {
    return;
  }

  // Details: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
  const config: AwsRumConfig = {
    sessionSampleRate: 0.01,
    guestRoleArn: "arn:aws:iam::488108494299:role/RUM-Monitor-us-east-1-488108494299-9466386438361-Unauth",
    identityPoolId: "us-east-1:138bec98-05c6-4e5c-91ba-126c5742904a",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance"],
    allowCookies: false,
    enableXRay: false,
    disableAutoPageView: true, // send page views manually
  };

  const rum: AwsRum = new AwsRum(
    "6dde6311-b802-409f-afb1-9ba4cf23b553",
    "0.0.0",
    "us-east-1",
    config,
  );

  // Send a page view manually.
  //
  // We don't send further events on navigation,
  // because we only need the initial page load, to collect web vitals.
  rum.recordPageView({
    pageId: window.location.pathname,
    pageTags: getPageTags(window.location.pathname),
  });

  console.debug("CloudWatch RUM initialized.");
};

export const startRUM = () => {
  try {
    initializeRum();
  } catch (e) {
    console.error(`Error initializing CloudWatch RUM: ${e}`);
  }
};
