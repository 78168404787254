import { useEffect } from "react";
import { useMeQuery } from "@/common/queries/queries";
import { onOpenPreferencesPopup } from "@/common/buses";

export const useCheckPreferences = () => {
  const { data } = useMeQuery();

  useEffect(() => {
    console.debug("Visitor info changes, checking for preferences.");

    if (!data) {
      return;
    }

    const countPreferences = data.preferences?.length || 0;

    if (countPreferences > 0) {
      return;
    }

    onOpenPreferencesPopup();
    console.debug("Showing the preferences dialog.");
  }, [data]);
};
