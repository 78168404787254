import ErrorBoundaryView from "components/ErrorBoundaryView";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { useEffect, useState } from "react";
import { App } from "components/app/App";

export const AppContainer = () => {
  const [canShow, setCanShow] = useState(false);

  useEffect(() => {
    setCanShow(true);
  }, []);

  if (!canShow) {
    return null;
  }

  return (
    <QueryClientProvider client={new QueryClient()}>
      <App />
    </QueryClientProvider>
  );
};
