import React from "react";
import { createRoot } from "react-dom/client";
import { reportLCP } from "@/common/utils/reportLCP";
import { startRUM } from "@/common/utils/rum";
import { AppContainer } from "components/app/AppContainer";

const root = createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<AppContainer />);

reportLCP();
startRUM();
