import { loadAuth, setAuth } from "@/common/utils/storage";
import { apiAuth } from "./index";
import sessionService from "@/common/services/session/sessionService";
import kindeService from "@/common/services/kinde/kindeService";

interface IAuthResult {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

interface ILoginParams {
  login: string;

  captcha: string;
  redirectTo?: string;
}

interface ISignupParams {
  username: string;
  email: string;
  captcha: string;
  redirectTo?: string;
}

const MS_IN_SEC = 1000;

export const isAuthenticated = async () => {
  try {
    return !!getStorageToken() || await kindeService.isAuthenticated();
  } catch (e) {
    console.warn(e);
    return false;
  }
};

export const getStorageToken = (): string | null => {
  try {
    const temporary = sessionStorage.getItem("auth_token");

    if (temporary) {
      return temporary;
    }
  } catch (e) {
    // ignore
  }

  const authData = loadAuth<{ exp: number; data?: IAuthResult }>();

  if (authData !== null) {
    const currentTime = (new Date().getTime() / MS_IN_SEC);

    if (authData.exp >= currentTime && authData.data?.access_token) {
      return authData.data.access_token;
    }

    setAuth();
  }

  return null;
};

export const login = async ({ login, captcha, redirectTo }: ILoginParams) => {
  const { login_hint, connection_id } = await apiAuth.login({ login, captcha });

  await kindeService.login({
    login_hint,
    connection_id,
    redirectTo,
  });
};

export const signup = async ({ username, email, captcha, redirectTo }: ISignupParams): Promise<void> => {
  const { login_hint, connection_id } = await apiAuth.signup({
    username,
    email,
    captcha,
  });

  await kindeService.register({
    login_hint,
    connection_id,
    redirectTo,
  });
};

export const logout = async () => {
  setAuth();
  await kindeService.logout();
};

export const getAccessToken = async (): Promise<string> => {
  const storageToken = getStorageToken();

  if (storageToken !== null) {
    return storageToken;
  }

  const isAuth = await kindeService.isAuthenticated();

  if (isAuth) {
    const token = await kindeService.getIdToken();

    if (token !== undefined) {
      return token;
    }
  }

  const session = await sessionService.getSession();
  return session.token;
};
