import { init, track, Identify, setUserId, identify, setDeviceId, getSessionId } from "@amplitude/analytics-browser";
import { loadAmplitudeTrackingRatio } from "@/common/utils/storage";
import { ValidPropertyType } from "@amplitude/analytics-types/lib/esm/event";
import { IUser } from "@/common/services/types";
import { isTrackingEnabled } from "@/common/utils/tracking";

interface IProperties {
  [key: string]: ValidPropertyType;
}

const API_KEY = import.meta.env.REACT_APP_AMPLITUDE_KEY;

/**
 * For naming conventions follow these rules:
 * https://help.amplitude.com/hc/en-us/articles/115000465251-Data-planning-playbook
 */
class AmplitudeAnalytics {
  private trackingEnabled: boolean | null = null;
  private initPromise: Promise<boolean> | null = null;

  async isTrackingEnabled() {
    await this.init();

    if (this.trackingEnabled === null) {
      const defaultTrackingRatio = 0.06;
      const trackingRatio = loadAmplitudeTrackingRatio() ?? defaultTrackingRatio;

      this.trackingEnabled = await isTrackingEnabled(trackingRatio);
    }

    return this.trackingEnabled;
  }

  async init() {
    if (!this.initPromise) {
      this.initPromise = new Promise(resolve => {
        setTimeout(async () => {
          try {
            const { promise } = init(API_KEY, { defaultTracking: false });
            await promise;
            resolve(true);
          } catch (e) {
            console.error(`Amplitude SDK initialization error: ${e}`);
            resolve(false);
          }
        }, 0);
      });
    }

    return await this.initPromise;
  }

  async identifyUser(user: IUser) {
    await this.init();

    setUserId(user.username);

    return this.updateUserProperties({
      "Verified": user.verified
    });
  }

  async setDeviceId(deviceId: string) {
    await this.init();

    setDeviceId(deviceId);
  }

  async getSessionId() {
    await this.init();

    return getSessionId();
  }

  async updateUserProperties(properties: IProperties) {
    if (!await this.isTrackingEnabled()) {
      return;
    }

    const identity = new Identify();

    Object.entries(properties).forEach(([key, value]) => {
      identity.set(key, value);
    });

    return await identify(identity).promise;
  }

  sendForcedFullscreenEntered() {
    return this.sendTrack("Forced Fullscreen Entered");
  }

  sendForcedFullscreenExitClicked() {
    return this.sendTrack("Forced Fullscreen Exit Clicked");
  }

  sendUploadStarted() {
    return this.sendTrack("Upload: Started");
  }

  sendUploadTagSelectionStarted() {
    return this.sendTrack("Upload: Tag Selection Started");
  }

  sendUploadTagSelectionEnded(tagsNumber: number) {
    return this.sendTrack("Upload: Tag Selection Ended", {
      "Tags Selected": tagsNumber
    });
  }

  sendUploadEnded() {
    return this.sendTrack("Upload: Ended");
  }

  sendWatchPageVisited(isLanding: boolean) {
    return this.sendTrack("Watch Page Visited", {
      "Is Landing": isLanding
    });
  }

  sendHomePageVisited(isLanding: boolean) {
    return this.sendTrack("Home Page Visited", {
      "Is Landing": isLanding
    });
  }

  sendGifViewed(id: string, isFullscreen: boolean) {
    return this.sendTrack("Gif Viewed", {
      "URL": window.location.pathname,
      "Id": id,
      "Fullscreen": isFullscreen,
    });
  }

  sendGifViewedMeaningful(id: string, isFullscreen: boolean) {
    return this.sendTrack("Gif Viewed Meaningful", {
      "URL": window.location.pathname,
      "Id": id,
      "Fullscreen": isFullscreen,
    });
  }

  sendGifLiked(id: string, isFullscreen: boolean, isMainGIF?: boolean) {
    return this.sendTrack("Gif Liked", {
      "URL": window.location.pathname,
      "Id": id,
      "Fullscreen": isFullscreen,
      ...(isMainGIF !== undefined ? { "Main GIF": isMainGIF } : {})
    });
  }

  sendGifUnliked(id: string, isFullscreen: boolean, isMainGIF?: boolean) {
    return this.sendTrack("Gif Unliked", {
      "URL": window.location.pathname,
      "Id": id,
      "Fullscreen": isFullscreen,
      ...(isMainGIF !== undefined ? { "Main GIF": isMainGIF } : {})
    });
  }

  sendGifAddedToCollection(id: string, isFullscreen: boolean) {
    return this.sendTrack("Gif Added To Collection", {
      "URL": window.location.pathname,
      "Id": id,
      "Fullscreen": isFullscreen,
    });
  }

  sendWatchPageNavigation(elementType: string, targetURL: string) {
    return this.sendTrack("Watch Page Navigation", {
      "URL": window.location.pathname,
      "Element Type": elementType,
      "Target URL": targetURL,
    });
  }

  sendFullscreenEntered() {
    return this.sendTrack("Fullscreen Entered", {
      "URL": window.location.pathname
    });
  }

  sendFullscreenExited() {
    return this.sendTrack("Fullscreen Exited", {
      "URL": window.location.pathname
    });
  }

  sendNavButtonClicked(section: string) {
    return this.sendTrack("Nav Button Clicked", {
      "URL": window.location.pathname,
      "Page Title": document.title,
      "Section": section
    });
  }

  sendSearchBarClicked() {
    return this.sendTrack("Search Bar Clicked", {
      "URL": window.location.pathname,
      "Page Title": document.title
    });
  }

  sendSearchQuerySubmitted(query: string) {
    return this.sendTrack("Search Query Submitted", {
      "URL": window.location.pathname,
      "Page Title": document.title,
      "Query": query
    });
  }

  sendLoginCompleted() {
    return this.sendTrack("Login Completed", {
      "URL": window.location.pathname,
      "Page Title": document.title
    });
  }

  sendQuestionnaireDisplayed(title: string, step: string) {
    return this.sendTrack("Questionnaire Displayed", { "Title": title, "Step": step });
  }

  sendQuestionnaireCompleted() {
    return this.sendTrack("Questionnaire Completed");
  }

  sendQuestionnaireClosed(title: string, step: string) {
    return this.sendTrack("Questionnaire Closed", { "Title": title, "Step": step });
  }

  sendPreferenceSubmitted(title: string, step: string, preference: string) {
    return this.sendTrack("Preference Submitted", { "Title": title, "Step": step, "Preference": preference });
  }

  private async sendTrack(eventName: string, eventProperties?: Record<string, string | boolean | number>) {
    if (!await this.isTrackingEnabled()) {
      return;
    }

    return track(eventName, eventProperties);
  }
}

export default new AmplitudeAnalytics();
