import { useEffect, useState } from "react";
import { usePortableVideoStore } from "@/common/store";
import { useLocation } from "react-router-dom";

export const usePortableVideo = () => {
  const videoRef = usePortableVideoStore((state) => state.videoRef);
  const targetRef = usePortableVideoStore((state) => state.targetRef);
  const videoAttributes = usePortableVideoStore((state) => state.videoAttributes);
  const updateVideo = usePortableVideoStore((state) => state.updateVideo);
  const [videoShown, setVideoShown] = useState<boolean>(false);
  const [videoMounted, setVideoMounted] = useState<boolean>(false);
  const location = useLocation();

  const onMounted: () => void = () => {
    setVideoMounted(true);
  };

  // prevent stop video when changing location and the same video is playing
  useEffect(() => {
    updateVideo({});
  }, [location]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.classList.remove(...videoRef.current.classList);
    targetRef.current?.appendChild(videoRef.current);
  }, [targetRef, videoMounted]);

  useEffect(() => {
    if (targetRef.current !== null) {
      setVideoShown(true);
    }
  }, [targetRef]);

  useEffect(() => {
    if (!videoRef.current || videoRef.current.src !== videoAttributes.src) {
      return;
    }

    videoRef.current.load();
  }, [videoAttributes.src, videoMounted]);

  return {
    videoRef,
    videoAttributes,
    videoShown,
    onMounted
  };
};
