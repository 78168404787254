import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "@/common/utils/routes";

declare global {
  interface Window {
    geolocation_blocked: string | null;
  }
}

interface IStateMapping {
  state: string;
  allowedPages: string[];
}

export const useGeoBlockedPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const stateMappings = useMemo<IStateMapping[]>(() => [
    { state: "texas", allowedPages: ["/texas-slate", "/guidelines"] },
    { state: "louisiana", allowedPages: ["/louisiana-slate", "/guidelines"] },
    { state: "kansas", allowedPages: ["/kansas-slate", "/guidelines"] },
  ], []);

  useEffect(() => {
    const blockedState = window.geolocation_blocked;

    if (!blockedState) {
      return;
    }

    const mapping = stateMappings.find(({ state }) => state === blockedState);

    if (!mapping) {
      return;
    }

    const isAllowedPage = mapping.allowedPages.some(page => location.pathname.includes(page));

    if (!isAllowedPage) {
      navigate(routes.geoBlockedPage(mapping.state));
    }
  }, [location.pathname, navigate, stateMappings]);
};
