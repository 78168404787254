import { BaseHttpService } from "./BaseHttpService";
import { ISessionInfo } from "@/common/services/types";

interface ISignupParams {
  username: string;
  email: string;
  captcha: string;
}

interface ISignupResponse {
  login_hint: string;
  connection_id: string;
}

interface ILoginParams {
  login: string;
  captcha: string;
}

interface ILoginResponse {
  login_hint: string;
  connection_id: string;
}

export default class AuthService extends BaseHttpService {
  public constructor(readonly url?: string) {
    super(url);
  }

  public async signup(params: ISignupParams): Promise<ISignupResponse> {
    return await this.post("/v2/auth/signup", params);
  }

  public async login(params: ILoginParams): Promise<ILoginResponse> {
    return await this.post("/v2/auth/login", params);
  }

  public getSession(sessionId?: string | null): Promise<ISessionInfo> {
    const query = new URLSearchParams();

    if (sessionId) {
      query.append("session_id", sessionId);
    }

    return this.get(`v2/auth/temporary${query.toString().length ? `?${query.toString()}` : ""}`);
  }
}
