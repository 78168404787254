import { metricsBus } from "@/common/buses";
import {
  useGetTrendingSearches,
  useGetNichesSearches,
  useGetCreatorsSearches,
  useGetHistorySearches,
  useGetTagsSearches
} from "@/common/queries/queries";
import { useEffect, useMemo } from "react";
import { useUserStore } from "@/common/store/userStore";

interface IUseSearchResults {
  searchText: string;
  isSearchBarOpen: boolean;
}

export const useSearchResults = ({
  searchText: searchValue,
  isSearchBarOpen
}: IUseSearchResults) => {
  const searchText = encodeURI(searchValue);
  const currentUser = useUserStore((state) => state.currentUser);
  const isUserLoggedIn = currentUser && (Object.entries(currentUser).length > 0);
  const isPerformTrendingSearch = useMemo(() => isUserLoggedIn && isSearchBarOpen, [isUserLoggedIn, isSearchBarOpen]);

  const { data: trendingTagsData, isLoading: trendingTagsLoading } = useGetTrendingSearches(isSearchBarOpen);
  const { data: historyData } = useGetHistorySearches(searchText, isPerformTrendingSearch);
  const { data: nichesData, isLoading: nichesLoading, isSuccess: nichesSuccess } = useGetNichesSearches(searchText);
  const { data: creatorsData, isLoading: creatorsLoading, isSuccess: creatorsSuccess } = useGetCreatorsSearches(searchText);
  const { data: tagsData, isLoading: searchedTagsLoading, isSuccess: searchedTagsSuccess } = useGetTagsSearches(searchText);

  const hasSearchData = useMemo(() => nichesSuccess && creatorsSuccess && searchedTagsSuccess, [nichesSuccess, creatorsSuccess, searchedTagsSuccess]);

  const sendSearchGaEvent = (query: string, totalCount: number) => {
    metricsBus.emit("searched", {
      query: query,
      totalCount: totalCount
    });
  };

  useEffect(() => {
    if (hasSearchData) {
      sendSearchGaEvent(
        searchText,
        (creatorsData?.items || []).length + (nichesData || []).length + (tagsData || []).length
      );
    }
  }, [hasSearchData]);

  return {
    creatorsResponse: { creatorsItems: creatorsData?.items || [], creatorsLoading },
    nichesResponse: { nichesItems: nichesData || [], nichesLoading },
    historyResponse: { historyItems: historyData || [] },
    trendingTagsResponse: { trendingTagsItems: trendingTagsData?.tags || [], trendingTagsLoading },
    searchedTagsResponse: { searchedTagsItems: tagsData || [], searchedTagsLoading },
  };
};
