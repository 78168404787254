import { loadMicrosoftClarityTrackingRatio } from "@/common/utils/storage";
import { isTrackingEnabled } from "@/common/utils/tracking";
import sessionService from "@/common/services/session/sessionService";

interface IMicrosoftClarity {
  (command: "consent"): void;
  (command: "identify", customId?: string, customSessionId?: string, customPageId?: string, friendlyName?: string): void;
  (command: "set", key: string, value: string | string[]): void;
  (command: "event", value: string): void;
  q: IArguments[];
}

declare global {
  interface Window {
    clarity: IMicrosoftClarity;
  }
}

const TRACKING_RATIO = 0.05;

class MicrosoftClarity {
  private trackingEnabled: boolean | null = null;

  public async startTracking() {
    if (!await this.isTrackingEnabled()) {
      return;
    }

    window.clarity = window.clarity || function() {
      (window.clarity.q = window.clarity.q || []).push(arguments);
    };

    const js = document.createElement("script");

    js.async = true;
    js.src = `https://www.clarity.ms/tag/${import.meta.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID}`;

    document.head.appendChild(js);
  }

  public async setDeviceId(deviceId: string) {
    if (!await this.isTrackingEnabled()) {
      return;
    }

    window.clarity("identify", undefined, deviceId);
  }

  public async setUserName(userName: string) {
    if (!await this.isTrackingEnabled()) {
      return;
    }

    const deviceId = (await sessionService.getSession()).session;

    window.clarity("identify", userName, deviceId, undefined, userName);
  }

  private async isTrackingEnabled() {
    if (this.trackingEnabled === null) {
      const trackingRatio = loadMicrosoftClarityTrackingRatio() ?? TRACKING_RATIO;
      this.trackingEnabled = await isTrackingEnabled(trackingRatio);
    }

    return this.trackingEnabled;
  }
}

export default new MicrosoftClarity();
