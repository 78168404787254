import { Dispatch, SetStateAction } from "react";
import { SearchResultItem } from "./SearchResultItem";
import { clsx } from "clsx";
import { TrendingTag } from "./TrendingTag";
import { Tag } from "./Tag";
import { IHistoryList, INichesItems, ICreatorsItems, ITrendingTags, ITagsList } from "@/common/services/types";
import { AdSearchLink } from "components/ads/AdSearchLink";
import { useAppStore } from "@/common/store/appStore";
import { useUserStore } from "@/common/store/userStore";
import { useDebounce } from "usehooks-ts";

import "./styles/GlobalSearch.scss";
import { useSearchResults } from "./useSearchResults";

interface IGlobalSearchProps {
  setSearchInput: Dispatch<SetStateAction<string>>;
  currentSearchValue: string;
  setCurrentTag: Dispatch<SetStateAction<string[]>>;
}

const MAX_6_ITEMS = 6;
const MAX_5_ITEMS = 5;
const MAX_4_ITEMS = 4;
const TIMEOUT = 500;

export const GlobalSearch = ({
  setSearchInput,
  currentSearchValue,
  setCurrentTag,
}: IGlobalSearchProps) => {
  const isSearchBarOpen = useAppStore((state) => state.isSearchBarOpen);
  const currentUser = useUserStore((state) => state.currentUser);
  const isUserLoggedIn = currentUser && !!Object.entries(currentUser).length;
  const debouncedPattern = useDebounce(currentSearchValue, TIMEOUT);

  const {
    creatorsResponse: { creatorsItems, creatorsLoading },
    nichesResponse: { nichesItems, nichesLoading },
    historyResponse: { historyItems },
    trendingTagsResponse: { trendingTagsItems, trendingTagsLoading },
    searchedTagsResponse: { searchedTagsItems, searchedTagsLoading },
  } = useSearchResults({ searchText: debouncedPattern, isSearchBarOpen });

  const renderTrendingSearches = () => {
    if (!isSearchBarOpen || currentSearchValue?.length) {
      return null;
    }

    if (trendingTagsLoading) {
      return <p className="GlobalSearch-ResultsState GlobalSearch-ResultsState_isSearch">searching...</p>;
    }

    return (
      <div className="GlobalSearch-TrendingTags">
        <h4>🔥️ Trending Searches</h4>
        {!trendingTagsItems?.length ? (
          <p className="GlobalSearch-ResultsState">no results for trending tags</p>
        ) : trendingTagsItems.slice(0, MAX_5_ITEMS).map((
          { name }: { name: string }, indx: number
        ) => (
          <TrendingTag
            setCurrentTag={setCurrentTag}
            setSearchInput={setSearchInput}
            title={name}
            key={indx}
          />
        )) }
        <AdSearchLink />
      </div>
    );
  };

  const renderNiches = () => {
    if (nichesLoading) {
      return <p className="GlobalSearch-ResultsState">searching...</p>;
    }

    if (!nichesItems.length) {
      return <p className="GlobalSearch-ResultsState">no results for niches</p>;
    }

    return (
      <ul className="GlobalSearch-List">
        {nichesItems.slice(0, MAX_4_ITEMS).map((niche: INichesItems) => (
          <SearchResultItem
            itemType="isNicheItem"
            itemData={niche}
            setSearchInput={setSearchInput}
            key={niche.id}
            setCurrentTag={setCurrentTag}
          />
        ))}
      </ul>
    );
  };

  const renderHistory = () => {
    if (!isSearchBarOpen || !historyItems.length) {
      return null;
    }

    if (!isUserLoggedIn) {
      return null;
    }

    return (
      <ul className="GlobalSearch-List GlobalSearch-List_History">
        {historyItems.slice(0, MAX_6_ITEMS).map((historyItem: IHistoryList, indx: number) => (
          <SearchResultItem
            itemType="isSavedItem"
            setSearchInput={setSearchInput}
            itemData={historyItem}
            key={indx}
            setCurrentTag={setCurrentTag}
          />
        ))}
      </ul>
    );
  };

  const renderCreators = () => {
    if (creatorsLoading) {
      return <p className="GlobalSearch-ResultsState">searching...</p>;
    }

    if (!creatorsItems.length) {
      return <p className="GlobalSearch-ResultsState">no results for creators</p>;
    }

    return (
      <ul className="GlobalSearch-List">
        {creatorsItems.slice(0, MAX_6_ITEMS).map((creator: ICreatorsItems, indx: number) => (
          <SearchResultItem
            itemType="isPersonItem"
            setSearchInput={setSearchInput}
            itemData={creator}
            key={indx}
            setCurrentTag={setCurrentTag}
          />
        ))}
      </ul>
    );
  };

  const renderTags = () => {
    if (searchedTagsLoading) {
      return <p className="GlobalSearch-ResultsState">searching...</p>;
    }

    if (!searchedTagsItems.length) {
      return <p className="GlobalSearch-ResultsState">no results for tags</p>;
    }

    return (
      <ul className="GlobalSearch-Tags">
        {searchedTagsItems.map((item: { text: string }, index: number) =>
          <Tag
            title={item.text}
            key={index}
            setSearchInput={setSearchInput}
            setCurrentTag={setCurrentTag}
          />
        )}
      </ul>
    );
  };

  const renderSearchResults = () => {
    if (!isSearchBarOpen || !currentSearchValue?.length) {
      return null;
    }

    return (
      <>
        <div className="GlobalSearch-Row">
          <div className="GlobalSearch-RowItem GlobalSearch-RowItem_isHistory">
            {renderHistory()}
          </div>
          <div className="GlobalSearch-RowItem GlobalSearch-RowItem_isTags">
            {renderTags()}
          </div>
        </div>
        <div className="GlobalSearch-Row">
          <div className="GlobalSearch-RowItem">
            {renderCreators()}
          </div>
          <div className="GlobalSearch-RowItem">
            {renderNiches()}
          </div>
        </div>
      </>
    );
  };

  const renderFirstSearches = () => {
    if (!isSearchBarOpen || currentSearchValue?.length) {
      return null;
    }

    return (
      <>
        {renderHistory()}
        {renderTrendingSearches()}
      </>
    );
  };

  return (
    <div className={clsx(["GlobalSearch", { "GlobalSearch_isOpen": isSearchBarOpen }])}>
      {renderFirstSearches()}
      {renderSearchResults()}
    </div>
  );
};
