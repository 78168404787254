const GIF_QUALITY = "gifQuality";

const GIF_SOUND = "gifSound";

const SESSION_DATA = "session_data";

const DISABLE_ADS = "disable_ads";

const AUTH_DATA = "auth_data";

const LS_KEY = "cookie_notice";

const EXPERIMENTS = "experiments";

const BOOSTED_GIFS = "boosted_gifs";

const DISMISS_SCROLL_MORE = "dismiss_scroll_more";

const ANONYMOUS_LIKED_TOAST = "anonymous_liked_toast";

const FIRST_PAGE_URL_KEY = "first_page_url";

const TOTAL_PLAYBACK_TIME = "total_playback_time";

const WATCHED_120S = "watched_120s";

const ADD_TAG_FEEDBACK = "add_tag_feedback";

const REMOVE_TAG_FEEDBACK = "remove_tag_feedback";

const TAG_FEEDBACK_TOOLTIP = "tag_feedback_tooltip";

const TOOLTIP_FEEDBACK = "tooltip_feedback";

const AMPLITUDE_TRACKING_RATIO = "amplitude_tracking_ratio";

const AMPLITUDE_SESSION_ID = "amplitude_session_id";

const MICROSOFT_CLARITY_TRACKING_RATIO = "microsoft_clarity_tracking_ratio";

const FULLSCREEN_FORCED = "fullscreen_forced";

const ANONYMOUS_NOTIFICATION = "anonymous_notification";

const FS_TOOLTIP = "fs_tooltip";

const ANONYMOUS_PASSION = "anonymous_passion";

const ANONYMOUS_SCENE = "anonymous_scene";

const ANONYMOUS_TAGS = "anonymous_tags";

const load = (key: string, storage: Storage | null): string | null => {
  if (!storage) {
    return null;
  }

  try {
    return storage.getItem(key);
  } catch (e) {
    console.debug(`Error reading ${key} from storage, probably shutting down.`, e);
    return null;
  }
};

const set = (key: string, storage: Storage | null) => <T>(value?: T | string | boolean | number | null) => {
  if (!storage) {
    return;
  }

  if (typeof value === "undefined" || value === null) {
    remove(key, storage);
    return;
  }

  if (typeof value === "string") {
    storage.setItem(key, value);
    return;
  }

  if (typeof value === "string") {
    storage.setItem(key, value);
    return;
  }

  storage.setItem(key, JSON.stringify(value));
};

const remove = (key: string, storage: Storage) => {
  storage.removeItem(key);
};

const getLocalStorage = () => {
  try {
    return localStorage;
  } catch (e) {
    const { name, message } = e as Error;
    // eslint-disable-next-line
    console.log(name, message);
  }

  return null;
};

const getSessionStorage = () => {
  try {
    return sessionStorage;
  } catch (e) {
    const { name, message } = e as Error;
    // eslint-disable-next-line
    console.log(name, message);
  }

  return null;
};

const ls = getLocalStorage();
const ss = getSessionStorage();

const getBoolean = (key: string, storage: Storage | null): boolean => {
  const raw = load(key, storage);
  return raw === "true";
};

const getString = (key: string, storage: Storage | null): string | null => {
  const v = load(key, storage);

  if (v !== null && v?.startsWith("\"") && v.endsWith("\"")) {
    return v.replaceAll("\"", "");
  }

  return v;
};

const getNumber = (key: string, storage: Storage | null): number | null => {
  const raw = load(key, storage);

  if (raw === null) {
    return null;
  }

  const number = Number(raw);

  if (Number.isNaN(number)) {
    return null;
  }

  return number;
};

const getObject = <T>(key: string, storage: Storage | null): T | null => {
  const v = load(key, storage);

  if (v === null) {
    return null;
  }

  if (!v.startsWith("{")) {
    return null;
  }

  try {
    return JSON.parse(v);
  } catch (e) {
    return null;
  }
};

const getArray = <T>(key: string, storage: Storage | null): T[] | null => {
  const value = load(key, storage);

  if (value === null) {
    return null;
  }

  try {
    const decoded: unknown = JSON.parse(value);

    if (!Array.isArray(decoded)) {
      return null;
    }

    return decoded;
  } catch (e) {
    return null;
  }
};

export const loadSession = <T>() => getObject<T>(SESSION_DATA, ls);

export const loadDisableAds = (): string | null => load(DISABLE_ADS, ls);

export const loadAuth = <T>() => getObject<T>(AUTH_DATA, ls);

export const loadGIFQuality = (): string | null => getString(GIF_QUALITY, ls);

export const loadGIFSound = () => getBoolean(GIF_SOUND, ls);

export const loadCookieNotice = (): string | null => getString(LS_KEY, ls);

export const loadExperiments = <T>() => getObject<T>(EXPERIMENTS, ls);

export const loadBoostedGifs = <T>() => getObject<T>(BOOSTED_GIFS, ls);

export const loadDismissScrollMore = () => getBoolean(DISMISS_SCROLL_MORE, ls);

export const loadFirstPageUrl = (): string | null => getString(FIRST_PAGE_URL_KEY, ls);

export const loadFullscreenForced = (): string | null => getString(FULLSCREEN_FORCED, ls);

export const loadAnonymousLikedToast = (): string | null => getString(ANONYMOUS_LIKED_TOAST, ls);

export const loadTotalPlaybackTime = () => getNumber(TOTAL_PLAYBACK_TIME, ss);

export const loadAnonymousNotification = <T>() => getArray<T>(ANONYMOUS_NOTIFICATION, ls);

export const loadWatched120s = () => getNumber(WATCHED_120S, ss);

export const loadTooltipFeedback = (): string | null => getString(TOOLTIP_FEEDBACK, ss);

export const loadAmplitudeTrackingRatio = () => getNumber(AMPLITUDE_TRACKING_RATIO, ls);

export const loadAmplitudeSessionId = () => getNumber(AMPLITUDE_SESSION_ID, ls);

export const loadAddTagFeedback = () => getBoolean(ADD_TAG_FEEDBACK, ls);

export const loadRemoveTagFeedback = () => getBoolean(REMOVE_TAG_FEEDBACK, ls);

export const loadTagFeedbackTooltip = () => getBoolean(TAG_FEEDBACK_TOOLTIP, ls);

export const loadMicrosoftClarityTrackingRatio = () => getNumber(MICROSOFT_CLARITY_TRACKING_RATIO, ls);

export const loadFullscreenTooltip = () => getBoolean(FS_TOOLTIP, ls);

export const loadAnonymousPassion = () => load(ANONYMOUS_PASSION, ls);

export const loadAnonymousScene = () => load(ANONYMOUS_SCENE, ls);

export const loadAnonymousTags = () => load(ANONYMOUS_TAGS, ls);

export const setGIFSound = (value: string) => set(GIF_SOUND, ls)(value);

export const setGIFQuality = (value: string) => set(GIF_QUALITY, ls)(value);

export const setCookieNotice = (value: string) => set(LS_KEY, ls)(value);

export const setSession = set(SESSION_DATA, ls);

export const setAuth = set(AUTH_DATA, ls);

export const setExperiments = set(EXPERIMENTS, ls);

export const setBoostedGifs = set(BOOSTED_GIFS, ls);

export const setDismissScrollMore = set(DISMISS_SCROLL_MORE, ls);

export const setFirstPageUrl = set(FIRST_PAGE_URL_KEY, ls);

export const setAddTagFeedback = set(ADD_TAG_FEEDBACK, ls);

export const setRemoveTagFeedback = set(REMOVE_TAG_FEEDBACK, ls);

export const setTagFeedbackTooltip = set(TAG_FEEDBACK_TOOLTIP, ls);

export const setAnonymousLikedToast = set(ANONYMOUS_LIKED_TOAST, ls);

export const setTotalPlaybackTime = set(TOTAL_PLAYBACK_TIME, ss);

export const setAnonymousNotification = set(ANONYMOUS_NOTIFICATION, ls);

export const setWatched120s = set(WATCHED_120S, ss);

export const setTooltipFeedback = set(TOOLTIP_FEEDBACK, ss);

export const setFullscreenForced = set(FULLSCREEN_FORCED, ls);

export const setFullscreenTooltip = set(FS_TOOLTIP, ls);

export const setAnonymousPassion = set(ANONYMOUS_PASSION, ls);

export const setAnonymousScene = set(ANONYMOUS_SCENE, ls);

export const setAnonymousTags = set(ANONYMOUS_TAGS, ls);

export const setAmplitudeSessionId = set(AMPLITUDE_SESSION_ID, ls);
