import { Dispatch, SetStateAction } from "react";
import { routes } from "@/common/utils/routes";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@/common/hooks/useQuery";

import "./styles/TrendingTag.scss";
import { useAppStore } from "@/common/store/appStore";

interface ITrendingTagsProps {
  title: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  setCurrentTag: Dispatch<SetStateAction<string[]>>;
}

export const TrendingTag = ({ title, setSearchInput, setCurrentTag }: ITrendingTagsProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const hideAllMenus = useAppStore((state) => state.hideAllMenus);

  const handleClickSearchItem = () => {
    setSearchInput("");
    setCurrentTag(prev => {
      if (prev.includes(title)) {
        navigate(routes.multipleTag(prev, query.get("order") ?? undefined, !!query.get("verified")));
        return prev;
      }

      if (prev.length >= 3){
        return prev;
      }

      navigate(routes.multipleTag([...prev, title], query.get("order") ?? undefined, !!query.get("verified")));
      return [...prev, title];
    });
    document?.getElementById("global-search-input")?.blur();
    hideAllMenus();
  };

  return (
    <div className="TrendingTag" onClick={handleClickSearchItem}>
      <div className="TrendingTag-Circle" />
      <span>{ title }</span>
    </div>
  );
};
