import { create } from "zustand";
import { loadGIFQuality, loadGIFSound, setGIFSound, setGIFQuality } from "@/common/utils/storage";

interface IAppState {
  // initial state
  isBurgerMenuOpen: boolean;
  isProfileMenuOpen: boolean;
  isSearchBarOpen: boolean;
  gifQuality: string;
  gifSound: boolean;
  isFullScreen: boolean;
  isForceFS: boolean;

  // actions
  toggleBurgerMenu: () => void;
  toggleProfileMenu: () => void;
  toggleSearchBar: () => void;
  setGifQuality: (gifQuality: string) => void;
  toggleGifSound: (gifSound: boolean) => void;
  toggleFullScreen: (isFullScreen: boolean) => void;
  toggleForceFS: (isForceFS: boolean) => void;
  hideAllMenus: () => void;
}

const getDefaultQuality = (): string => {
  const query = new URLSearchParams(window.location.search);
  return query.get("from") === "embed" ? "hd" : "sd";
};

export const useAppStore = create<IAppState>(
  (set) => ({
    // initial state
    isBurgerMenuOpen: false,
    isProfileMenuOpen: false,
    isSearchBarOpen: false,
    gifQuality: loadGIFQuality() || getDefaultQuality(),
    gifSound: loadGIFSound(),
    isFullScreen: false,
    isForceFS: false,

    // actions
    toggleBurgerMenu: () => set((state) => ({ isBurgerMenuOpen: !state.isBurgerMenuOpen })),
    toggleProfileMenu: () => set((state) => ({ isProfileMenuOpen: !state.isProfileMenuOpen })),
    toggleSearchBar: () => set((state) => ({ isSearchBarOpen: !state.isSearchBarOpen })),
    setGifQuality: (gifQuality: string) => set(() => {
      setGIFQuality(gifQuality);
      return { gifQuality };
    }),
    toggleGifSound: (gifSound: boolean) => set(() => {
      setGIFSound(JSON.stringify(gifSound));
      return { gifSound };
    }),
    toggleFullScreen: (isFullScreen: boolean) => set(() => ({ isFullScreen })),
    toggleForceFS: (isForceFS: boolean) => set(() => ({ isForceFS })),
    hideAllMenus: () => set(() => ({
      isSearchBarOpen: false,
      isBurgerMenuOpen: false,
      isProfileMenuOpen: false,
    })),
  }),
);
