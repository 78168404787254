import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadFirstPageUrl, setFirstPageUrl } from "@/common/utils/storage";
import { bus } from "@/common/buses/metrics/bus";

export const useFirstPageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const firstPageURL = loadFirstPageUrl();
    if (!firstPageURL) {
      setFirstPageUrl(location.pathname);
      bus.emit("firstPageVisited", { path: location.pathname });
    }
  }, []);
};
